import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import {
  dmmShowAppropriateForm,
  dmmPersistForm,
  dmmShowConnect,
  dmmShowRegister } from 'es6/redux/modules/auth/dmm';
import { t } from 'es6/services/localization';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import ConnectForm from 'es6/containers/registration/dmm-signup/connect-form';
import RegisterForm from 'es6/containers/registration/dmm-signup/register-form';
import NativeSection from 'es6/components/registration/authentication-box/native-section';
import PageContainer from 'es6/components/common/page-container';
import { getDmmForm } from 'es6/helpers/selectors';
import Footer from 'es6/components/registration/common/footer';

class DmmSignup extends Component {
  componentWillMount() {
    const { onWillMount } = this.props;

    if (onWillMount) {
      onWillMount();
    }
  }

  render() {
    const { onShowRegister, onShowConnect, showConnectForm } = this.props;

    const onClick = (callback) => (e) => {
      e.preventDefault();
      callback();
    };

    return (
      <PageContainer>
        <AuthenticationBox>
          <NativeSection>
            {showConnectForm ? <ConnectForm /> : <RegisterForm />}
          </NativeSection>
        </AuthenticationBox>

        <Footer>
          {showConnectForm ?
            <a href="" onClick={onClick(onShowRegister)}>{t('dmm-signup.register-link')}</a> :
            <a href="" onClick={onClick(onShowConnect)}>{t('dmm-signup.connect-link')}</a>}
        </Footer>
      </PageContainer>
    );
  }
}

DmmSignup.propTypes = {
  onWillMount: PropTypes.func,
  onShowConnect: PropTypes.func.isRequired,
  onShowRegister: PropTypes.func.isRequired,
  showConnectForm: PropTypes.bool,
};

function mapStateToProps(state) {
  return getDmmForm(state);
}

function mapDispatchToProps(dispatch) {
  return {
    onWillMount: () => {
      dispatch(dmmShowAppropriateForm());
    },

    onShowConnect: () => {
      const action = dmmShowConnect();
      dispatch(dmmPersistForm(action.type));
      dispatch(action);
    },

    onShowRegister: () => {
      const action = dmmShowRegister();
      dispatch(dmmPersistForm(action.type));
      dispatch(action);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DmmSignup);
