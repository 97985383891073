import React from 'react';
import Input from 'es6/components/registration/inputs/input';
import { t } from 'es6/services/localization';

/**
 * Overrides the error message with out own custom message.
 *
 * @param {Event} e
 */
function checkValidity(e) {
  const { target } = e;
  const { validity } = target;

  // Unset the custom validity error message when the target validates.
  if (validity.customError && !validity.typeMismatch) {
    target.setCustomValidity('');
  } else if (validity.typeMismatch) {
    target.setCustomValidity(t('input.email.pattern-mismatch'));
  }
}

export default function EmailInput(props) {
  return (
    <Input
      type="email"
      name="user[email]"
      placeholder={t('placeholder.email')}
      required
      onChange={checkValidity}
      onInvalid={checkValidity}
      {...props}
    />
  );
}
