import Logger from 'es6/services/logger';

function logger({ dispatch, getState }) {
  return next => action => {
    Logger.info('Redux Action', action);
    const result = next(action);
    Logger.info('Redux State', getState());
    return result;
  };
}

export default logger;
