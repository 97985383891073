import { makeActionCreator } from 'es6/helpers/redux';

export const OPEN_CLIENT = 'auth/oauth/OPEN_CLIENT';

/**
 * @param {String} path     the URL path to open
 * @param {Number} width    width of the window to open
 * @param {Number} height   height of the window to open
 */
export const openClient = makeActionCreator(OPEN_CLIENT, 'path', 'width', 'height');
