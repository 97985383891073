import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import PageContainer from 'es6/components/common/page-container';
import Header from 'es6/components/registration/common/header';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import FlashMessages from 'es6/containers/common/flash-messages';
import PhoneSection from 'es6/components/registration/authentication-box/phone-section';
import PhoneConfirmationForm from 'es6/components/registration/forms/phone-confirmation-form';
import PhoneResendCodeForm from 'es6/components/registration/forms/phone-resend-code-form';
import { generatePhoneConfirmCode } from 'es6/core/paths';
import LogoutForm from 'es6/components/registration/forms/logout-form';
import Footer from 'es6/components/registration/common/footer';

function PhoneConfirmation() {
  return (
    <PageContainer>
      <Header>
        <h1>{t('confirm-your-phone.title')}</h1>
        <h3>{t('confirm-your-phone.subtitle')}</h3>
      </Header>

      <AuthenticationBox>
        <PhoneSection>
          <div className={css(styles.formContainer)}>
            <FlashMessages />
            <PhoneResendCodeForm
              action={generatePhoneConfirmCode()}
            />
            <PhoneConfirmationForm />
            <LogoutForm />
          </div>
        </PhoneSection>
      </AuthenticationBox>

      <Footer />
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    padding: '10px 0px 20px 0px',
    textAlign: 'center',
  },
});

export default PhoneConfirmation;
