import React, { PropTypes } from 'react';
import { Country } from 'es6/core/countries';

export default function Flag({
  country,
  className,
}) {
  return (
    <img
      alt={country.name}
      src={`/_assets/flags/${country.isoCode.toLowerCase()}.svg`}
      className={className}
    />
  );
}

Flag.propTypes = {
  country: PropTypes.instanceOf(Country).isRequired,
  className: PropTypes.string,
};
