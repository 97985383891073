/* eslint-disable default-case */
import { makeActionCreator } from 'es6/helpers/redux';

const SESSION_DMM_FORM = 'dmmForm';

// Action Types
const DMM_CONNECT = 'auth/dmm/DMM_CONNECT';
const DMM_REGISTER = 'auth/dmm/DMM_REGISTER';
const DMM_SHOW_CONNECT = 'auth/dmm/DMM_SHOW_CONNECT';
const DMM_SHOW_REGISTER = 'auth/dmm/DMM_SHOW_REGISTER';

export const dmmConnect = makeActionCreator(DMM_CONNECT);
export const dmmRegister = makeActionCreator(DMM_REGISTER);
export const dmmShowConnect = makeActionCreator(DMM_SHOW_CONNECT);
export const dmmShowRegister = makeActionCreator(DMM_SHOW_REGISTER);

/**
 * Retrieves which form was last viewed from the database and sets the
 * start so that form renders.
 */
export const dmmShowAppropriateForm = (storage = sessionStorage) => (dispatch) => {
  const type = storage.getItem(SESSION_DMM_FORM);

  if (type === DMM_SHOW_CONNECT) {
    dispatch(dmmShowConnect());
  } else if (type === DMM_SHOW_REGISTER) {
    dispatch(dmmShowRegister());
  }
};

/**
 * Persists which form was shown for DMM and then shows it.
 *
 * @param {String} type         the action type to preserve
 */
export const dmmPersistForm = (type) => (dispatch) => {
  sessionStorage.setItem(SESSION_DMM_FORM, type);
};

const DEFAULT_STATE = Object.freeze({ isLoading: false, showConnectForm: false });

export default function reducer(state = DEFAULT_STATE, action = {}) {
  const { type } = action;

  switch (type) {
    case DMM_CONNECT:
    case DMM_REGISTER:
      return Object.assign({}, state, { isLoading: true });
    case DMM_SHOW_CONNECT:
      return Object.assign({}, state, { showConnectForm: true });
    case DMM_SHOW_REGISTER:
      return Object.assign({}, state, { showConnectForm: false });
  }

  return state;
}
