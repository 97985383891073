import React, { PropTypes } from 'react';

class LinkForm extends React.Component {
  constructor(props) {
    super(props);

    this.formSubmitted = false;
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e) {
    e.preventDefault();
    if (!this.formSubmitted) {
      this.formSubmitted = true;
      e.currentTarget.parentElement.submit();
    }
  }
}

LinkForm.propTypes = {
  action: PropTypes.string,
};

export default LinkForm;
