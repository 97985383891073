import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';

function BottomLink({ children }) {
  return (
    <div className={css(styles.bottomLink)}>{children}</div>
    );
}

BottomLink.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  bottomLink: {
    textAlign: 'center',
  },
});

export default BottomLink;
