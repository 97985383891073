import { createSelector } from 'reselect';

export const getAuth = (state) => state.auth;
export const getDmmForm = createSelector(getAuth, (auth) => auth.dmm);
export const getRegisterForm = createSelector(getAuth, (auth) => auth.register);
export const getPhone = createSelector(getAuth, (auth) => auth.phone);
export const getUsername = createSelector(getAuth, (auth) => auth.username);
export const getCode = createSelector(getAuth, (auth) => auth.code);
export const getForm = createSelector(getAuth, (auth) => auth.form);

export const getEnv = (state) => state.env;
export const getCsrfToken = createSelector(getEnv, (env) => env.csrfToken);
export const getFlash = createSelector(getEnv, (env) => env.flash);
export const getLanguageCode = createSelector(getEnv, (env) => env.languageCode);
export const getRailsForm = createSelector(getEnv, (env) => env.form);
export const getSiteConfiguration = createSelector(getEnv, (env) => env.siteConfiguration);

export const getModals = (state) => state.modals;
export const getCountryCode = createSelector(getModals, (modals) => modals.countryCode);
