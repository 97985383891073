import { connect } from 'react-redux';
import CodeInput from 'es6/components/registration/inputs/code';
import { setCode } from 'es6/redux/modules/auth/code';
import { getCode } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const code = getCode(state);
  const value = code && code.code || '';

  return { value };
}

function mapDispatchToProps(dispatch) {
  return {
    onCodeChange: (code) => dispatch(setCode(code)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeInput);
