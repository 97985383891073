import { connect } from 'react-redux';
import SignupForm from 'es6/components/registration/forms/signup-form';
import { register } from 'es6/redux/modules/auth/register';
import { getRailsForm, getRegisterForm, getSiteConfiguration } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const form = getRailsForm(state).toJS();
  const registerForm = getRegisterForm(state).toJS();
  const siteConfiguration = getSiteConfiguration(state);
  return { ...form, ...registerForm, siteConfiguration };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: () => dispatch(register()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
