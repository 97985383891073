import React from 'react';
import { StyleSheet, css } from 'aphrodite';

export default function OauthSection({ children }) {
  return (
    <section className={css(styles.oauthSection)}>
      <div className={css(styles.inner)}>
        {children}
      </div>
    </section>
  );
}

OauthSection.propTypes = {
  children: React.PropTypes.node,
};

const styles = StyleSheet.create({
  oauthSection: {
    display: 'flex',
    padding: '2em 3em',
    backgroundColor: '#F6F5EF',
    textAlign: 'center',
    flexBasis: 'calc(390px - 6em)',
  },
  inner: {
    maxWidth: '220px',
    margin: 'auto',
  },
});
