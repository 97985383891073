import React from 'react';
import { StyleSheet, css } from 'aphrodite';

export default function AuthenticationBox({ children }) {
  return (
    <div>
      <div className={css(styles.authenticationBox)}>{children}</div>
    </div>
  );
}

AuthenticationBox.propTypes = {
  children: React.PropTypes.node,
};

const styles = StyleSheet.create({
  authenticationBox: {
    borderRadius: '5px',
    margin: '3.5em auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});
