import React, { PropTypes } from 'react';
import Input from 'es6/components/registration/inputs/input';
import { t } from 'es6/services/localization';

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.checkValidity = this.checkValidity.bind(this);
  }

  onChange(e) {
    this.checkValidity(e);
    this.props.onPhoneChange(e.target.value);
  }

  /**
   * Overrides the error message with our own custom message.
   *
   * @param {Event} e
   */
  checkValidity(e) {
    const { target } = e;
    const { validity } = target;

    // Unset the custom validity error message when the target validates.
    if (validity.customError && !validity.typeMismatch) {
      target.setCustomValidity('');
    } else if (validity.typeMismatch) {
      target.setCustomValidity(t('input.phone.pattern-mismatch'));
    }
  }

  render() {
    return (
      <Input
        type="tel"
        placeholder={t('placeholder.phone')}
        required
        onChange={this.onChange}
        onInvalid={this.checkValidity}
        {...this.props}
      />
    );
  }
}

PhoneInput.propTypes = {
  onPhoneChange: PropTypes.func,
};

export default PhoneInput;
