import { Record } from 'immutable';
import { createReducer, makeActionCreator } from 'es6/helpers/redux';
import { SET_ENV } from 'es6/redux/modules/env';

export const REGISTER = 'auth/register/REGISTER';
export const REGISTER_SHOW_COUPON_FIELD = 'auth/register/REGISTER_SHOW_COUPON_FIELD';

export const register = makeActionCreator(REGISTER);
export const registerShowCouponField = makeActionCreator(REGISTER_SHOW_COUPON_FIELD);

const RegisterForm = Record({
  isLoading: false,
  showCouponField: false,
  forceCountry: undefined,
  showForceCountryField: false,
});

const INITIAL_STATE = new RegisterForm();

export default createReducer(INITIAL_STATE, {
  [REGISTER]: (state) => state.set('isLoading', true),

  [REGISTER_SHOW_COUPON_FIELD]: (state) => state.set('showCouponField', true),

  [SET_ENV]: (state, { form }) => {
    const coupon = form && form.coupon;
    const showCouponField = typeof coupon !== 'undefined' && coupon !== null;
    const forceCountry = form && form.forceCountry;
    const showForceCountryField = !!forceCountry;

    return state.merge({
      showCouponField,
      forceCountry,
      showForceCountryField,
    });
  },
});
