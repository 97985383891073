import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Config from 'es6/core/config';
import COUNTRIES from 'es6/core/countries';
import CountryCode from 'es6/components/registration/modals/country-code';
import { close, setFilter } from 'es6/redux/modules/modals/country-code';
import { getCountryCode, getSiteConfiguration } from 'es6/helpers/selectors';

const mapStateToProps = (state, ownProps) => {
  const { isOpen, filter } = getCountryCode(state);
  const siteConfiguration = getSiteConfiguration(state);
  const isoCode = siteConfiguration && siteConfiguration.isoCode;
  let allowedCountryCodes = siteConfiguration && siteConfiguration.allowedCountryCodes;

  let countries = COUNTRIES;

  // Restrict country codes
  if (allowedCountryCodes && !(allowedCountryCodes.length === 1 && allowedCountryCodes[0] === 'any')) {
    allowedCountryCodes = allowedCountryCodes.map((country) => country.replace('+', ''));

    const query = ownProps.location && ownProps.location.query;

    // Check if a country was forced
    if (!!query && !!query.force_country && (Config.isDevelopment || Config.isTesting)) {
      if (allowedCountryCodes.indexOf(query.force_country) === -1) {
        const country = COUNTRIES.getCountryByIsoCode(query.force_country);
        if (country && country.countryCode) {
          allowedCountryCodes.push(country.countryCode);
        }
      }
    }

    countries = countries.filter(country => allowedCountryCodes.includes(country.countryCode));
  }

  // Sort by the iso code. If there is one available in the SiteConfiguration,
  // put that country at the top.
  countries = countries.filter(country => country.contains(filter.toLowerCase()))
                       .sort((a, b) => {
                         if (a.isoCode === isoCode) {
                           return -1;
                         } else if (b.isoCode === isoCode) {
                           return 1;
                         } else if (a.isoCode < b.isoCode) {
                           return -1;
                         } else {
                           return 1;
                         }
                       });

  return { countries, isOpen, filter };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(close()),
  onFilter: (filter) => dispatch(setFilter(filter)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryCode));
