import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import CountryCodePhoneInput from 'es6/containers/registration/inputs/country-code-phone';
import EmailInput from 'es6/components/registration/inputs/email';

const styles = StyleSheet.create({
  a: {
    cursor: 'pointer',
  },
});

function UsernameInput({
  name,
  inputMethod,
  enabledChangeInputMethod,
  onChangeInputMethod,
}) {
  let input;
  let switchText;

  switch (inputMethod) {
    case 'email':
      input = (<EmailInput
        name={name}
        type="text"
        placeholder={t('placeholder.username')}
      />);
      switchText = t('switch-input.to-phone');
      break;
    case 'phone':
      input = <CountryCodePhoneInput name={name} />;
      switchText = t('switch-input.to-email');
      break;
    default:
      return false;
  }

  return (
    <div>
      {input}
      {enabledChangeInputMethod &&
        <a
          className={css(styles.a)}
          onClick={() =>
            onChangeInputMethod(inputMethod === 'phone' ? 'email' : 'phone')}
        >
          {switchText}
        </a>}
    </div>
  );
}

UsernameInput.propTypes = {
  name: PropTypes.string,
  inputMethod: PropTypes.string,
  enabledChangeInputMethod: PropTypes.bool,
  onChangeInputMethod: PropTypes.func,
};

export default UsernameInput;
