import { Record } from 'immutable';
import { createReducer, makeActionCreator } from 'es6/helpers/redux';

const CLOSE = 'modals/country-code/CLOSE';
const OPEN = 'modals/country-code/open';
const SET_FILTER = 'modals/country-code/SET_FILTER';

export const close = makeActionCreator(CLOSE);
export const open = makeActionCreator(OPEN);
export const setFilter = makeActionCreator(SET_FILTER, 'filter');

export const CountryCode = Record({ isOpen: false, filter: '' });

export const INITIAL_STATE = new CountryCode();

export default createReducer(INITIAL_STATE, {
  [CLOSE]: () => INITIAL_STATE,
  [OPEN]: (state) => state.set('isOpen', true),
  [SET_FILTER]: (state, { filter }) => state.set('filter', filter),
});
