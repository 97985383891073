import { makeActionCreator } from 'es6/helpers/redux';
import { Map, Record } from 'immutable';
import handleActions from 'es6/helpers/handle-actions';
import SiteConfiguration from 'es6/models/site-configuration';
import { getSiteConfiguration } from 'es6/core/api';
import cookies from 'es6/services/cookies';

export const SET_ENV = 'env/SET_ENV';
const CLEAR_FORM = 'env/CLEAR_FORM';
const CLEAR_FLASH = 'env/CLEAR_FLASH';

/**
 * Used to pass in env variables.
 *
 * @param {Object} env
 * @param {String} env.csrfToken          required for form submissions to env
 * @param {Object} env.flash
 * @param {Object} env.form               input fields for the rendered form
 * @param {String} env.languageCode
 * @param {Object} env.siteConfiguration
 */
export const setEnv = (env) => Object.assign({}, env, { type: SET_ENV });
export const clearForm = makeActionCreator(CLEAR_FORM);
export const clearFlash = makeActionCreator(CLEAR_FLASH);

/**
 * Running this will set up some basic env variables passed down
 * from rails.
 *
 * @param {Object} env                    some fields from the rails env
 * @param {String} env.csrfToken          required for form submissions to env
 * @param {Object} env.form               input fields for the rendered form
 * @param {String} env.languageCode
 * @param {Boolean} dispatchFlashMessages if true, initEnv will remove all flash
 *  messages from the cookie and put them into the redux store instead
 */
export const initEnv = ({ csrfToken, form, languageCode, dispatchFlashMessages } = {}) => (dispatch) => {
  // This sets a cookie that is used by the rails register_user method. It
  // will record the users timezone (as specified by their client time).
  // A prefered method might be passing it in as a parameter upon signup,
  // but keep in mind that there are different oauth providers.
  cookies.set('timezone_offset', new Date().getTimezoneOffset());

  // There is an after filter in rails that discards and moves flash
  // messages to cookies (reference lib/notification.rb). This code reproduces
  // some of the behavior that the growls perform by looking up the flash message
  // and putting it into the store.
  const flash = {};

  if (dispatchFlashMessages) {
    for (const key of ['error', 'notice', 'warn', 'message']) {
      const flashKey = `flash.${key}`;
      const json = cookies.getJSON(flashKey);
      const value = json && json.text ? json.text : json;

      // Rails encodes the cookies. If there is a value, decode it.
      flash[key] = value;
      cookies.remove(flashKey);
    }
  }

  return getSiteConfiguration().then(siteConfiguration =>
    dispatch(setEnv({ csrfToken, flash, form, languageCode, siteConfiguration })));
};

export const EnvRecord = Record({
  csrfToken: undefined,
  form: Map(),
  flash: Map(),
  languageCode: undefined,
  loaded: false,
  siteConfiguration: undefined,
});

const DEFAULT_STATE = new EnvRecord();

/**
 * NOTE: If or when the migration to SPA happens, a history change event
 *       should be monitored here to clear the flash and form field.
 */
export default handleActions(DEFAULT_STATE, {
  [CLEAR_FLASH]: (state) => state.set('flash', Map()),

  [CLEAR_FORM]: (state) => state.delete('form'),

  [SET_ENV]: (state, { csrfToken, flash, form, languageCode, siteConfiguration } = {}) => {
    // Only allow the environment to be loaded once.
    if (!state.loaded) {
      const newState = state.merge({
        csrfToken,
        flash: Map(flash),
        form: Map(form),
        loaded: true,
        languageCode,
        siteConfiguration: new SiteConfiguration(siteConfiguration),
      });

      return newState;
    } else {
      return state;
    }
  },
});
