export const Keys = Object.freeze({
  ENTER: 13,
  SPACE: 32,
  TAB:  9,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  BACKSPACE: 8,
  BACKSLASH:  220,
  DELETE: 46,
  ESC: 27,
  PERIOD: 190,
  COMMA: 188,
  APOSTROPHE: 222,
  HYPHEN: 45,
  PGUP: 33,
  PGDOWN: 34,
  S: 83,
  H: 72,
  J: 74,
  K: 75,
  L: 76,
  I: 73,
  R: 82,
  C: 67,
  N: 78,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  NUM_0: 96,
  NUM_1: 97,
  NUM_2: 98,
  NUM_3: 99,
  NUM_4: 100,
  NUM_5: 101,
  NUM_6: 102,
  NUM_7: 103,
  NUM_8: 104,
  NUM_9: 105,
});

// Helper functions to more easily support VIM keys.
const isLeft        = keyCode => keyCode === Keys.LEFT || keyCode === Keys.H;
const isRight       = keyCode => keyCode === Keys.RIGHT || keyCode === Keys.L;
const isUp          = keyCode => keyCode === Keys.UP || keyCode === Keys.K;
const isDown        = keyCode => keyCode === Keys.DOWN || keyCode === Keys.J;
const isDirectional = keyCode => isLeft(keyCode) || isRight(keyCode) || isUp(keyCode) || isDown(keyCode);

export const KeyHelpers = { isLeft, isRight, isUp, isDown, isDirectional };

export default Keys;
