import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { forgotPassword, forgotPasswordPhone } from 'es6/core/paths';
import { t } from 'es6/services/localization';
import SiteConfiguration, { BRANDING_ENGOO } from 'es6/models/site-configuration';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import DmmOauthButton from 'es6/components/registration/oauth/dmm-oauth-button';
import OauthSection from 'es6/components/registration/authentication-box/oauth-section';
import NativeSection from 'es6/components/registration/authentication-box/native-section';
import PageContainer from 'es6/components/common/page-container';
import LoginForm from 'es6/containers/registration/forms/login-form';
import Header from 'es6/components/registration/common/header';
import Footer from 'es6/components/registration/common/footer';
import BottomLink from 'es6/components/registration/common/bottom-link';

function Login({ siteConfiguration }) {
  const { branding } = siteConfiguration;
  const { loginMethods: { dmm } } = siteConfiguration;
  const { registrationMethods: { phone } } = siteConfiguration;
  let forgotPasswordPath = phone ? forgotPasswordPhone() : forgotPassword();

  return (
    <PageContainer>
      <Header>
        <h1>{t('login.header')}</h1>
        {
          branding === BRANDING_ENGOO &&
            <div className={css(styles.explanation)}>
              {t('login.engoo-explanation').split('\n').map((explanation, key) =>
                <div key={key}>{explanation}</div>)}
            </div>
        }
      </Header>

      <AuthenticationBox>
        <NativeSection>
          <h5 className={css(styles.h5)}>
            {t('login.label')}
          </h5>

          <LoginForm />

          <p><a href={forgotPasswordPath}>{t('login.forget-password-link')}</a></p>
        </NativeSection>

        {dmm &&
          <OauthSection>
            <div className="oauth-buttons">
              <DmmOauthButton>{t('login.dmm-button')}</DmmOauthButton>
            </div>
          </OauthSection>
        }
      </AuthenticationBox>
      <BottomLink>
        <a href="/signup">
          {t('login.signup-link')}
        </a>
      </BottomLink>

      <Footer />
    </PageContainer>
  );
}

Login.propTypes = {
  siteConfiguration: PropTypes.instanceOf(SiteConfiguration),
};


const styles = StyleSheet.create({
  explanation: {
    marginTop: '1em',
    fontSize: '18px',
  },
  h5: {
    marginBottom: '2em',
  },
});


export default Login;
