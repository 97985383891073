/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React, { PropTypes } from 'react';

export const CHEVRON_LEFT = 'glyph-chevron-left';

export const CHEVRON_RIGHT = 'glyph-chevron-right';

export const CORRECT_RESULT = 'glyph-correct-result';

export const INCORRECT_RESULT = 'glyph-incorrect-result';

export const JAPANESE_CHARACTER = 'glyph-japanese-character';

export const KEYBOARD = 'glyph-keyboard';

export const PAPERCLIP = 'glyph-paperclip';

export const VOLUME_MEDIUM = 'glyph-volume-medium';

export const PAUSE = 'glyph-pause';

export const GEAR = 'glyph-gear';

export const CROSS = 'glyph-cross-soft';

export const REMOVE = 'glyph-remove';

export const REVIEW = 'glyph-reload-alternate';

export const TRIANGLE_DOWN = 'glyph-triangle-down';

export default function Glyph(props) {
  const className = classnames('glyph', props.type, props.className);
  return (<i {...props} className={className} />);
}

Glyph.propTypes = {
  type: PropTypes.string.isRequired,
};
