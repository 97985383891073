import React from 'react';
import { t } from 'es6/services/localization';
import { confirmPhone } from 'es6/core/paths';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import BrandButton from 'es6/containers/common/buttons/brand-button';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import CodeInput from 'es6/containers/registration/inputs/code';

function PhoneConfirmationForm() {
  return (
    <AuthenticationForm isInline action={confirmPhone()} delay>
      <CsrfTokenInput />

      <InputContainer>
        <CodeInput
          name="confirmation_code"
          placeholder={t('phone-number.confirm-code')}
        />
      </InputContainer>

      <InputContainer>
        <BrandButton type="submit">
          {t('confirm-your-phone.submit')}
        </BrandButton>
      </InputContainer>
    </AuthenticationForm>
  );
}

export default PhoneConfirmationForm;
