import { connect } from 'react-redux';
import ToggleLanguage from 'es6/components/common/toggle-language';
import { getLanguageCode, getSiteConfiguration } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const languageCode = getLanguageCode(state);
  const siteConfiguration = getSiteConfiguration(state);
  return { languageCode, siteConfiguration };
}

export default connect(mapStateToProps)(ToggleLanguage);
