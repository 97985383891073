import React, { PropTypes } from 'react';
import { t } from 'es6/services/localization';
import { signup } from 'es6/core/paths';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import FlashMessages from 'es6/containers/common/flash-messages';
import BrandButton from 'es6/containers/common/buttons/brand-button';
import CouponInput from 'es6/components/registration/inputs/coupon';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import EmailInput from 'es6/components/registration/inputs/email';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import CountryCodePhoneInput from 'es6/containers/registration/inputs/country-code-phone';
import PasswordInput from 'es6/components/registration/inputs/password';
import ReferralInput from 'es6/components/registration/inputs/referral';
import ForceCountryInput from 'es6/components/registration/inputs/force-country.js';
import SiteConfiguration, { PAYMENT_MODEL_FREE } from 'es6/models/site-configuration';

function SignupForm(props) {
  const { payment: { paymentModel }, registrationMethods } = props.siteConfiguration;
  const isFree = paymentModel === PAYMENT_MODEL_FREE;

  return (
    <AuthenticationForm action={signup()} delay>
      <FlashMessages />
      <CsrfTokenInput />

      {registrationMethods.email &&
        <InputContainer>
          <EmailInput />
        </InputContainer>}

      {registrationMethods.phone &&
        <InputContainer>
          <CountryCodePhoneInput
            name="user[user_phone_number][number]"
          />
        </InputContainer>}

      <InputContainer>
        <PasswordInput />
      </InputContainer>

      {!isFree && (props.coupon || props.showCouponField) &&
        <InputContainer><CouponInput defaultValue={props.coupon} /></InputContainer>}

      {props.referral && <InputContainer><ReferralInput value={props.referral} /></InputContainer>}

      {props.showForceCountryField && <InputContainer><ForceCountryInput value={props.forceCountry} /></InputContainer>}

      <InputContainer>
        <BrandButton type="submit">
          {t('signup-form.submit')}
        </BrandButton>
      </InputContainer>
    </AuthenticationForm>
  );
}

SignupForm.propTypes = {
  coupon: PropTypes.string,
  isLoading: PropTypes.bool,
  onChooseCountry: PropTypes.func,
  onPhoneChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  countryCode: PropTypes.string,
  phone: PropTypes.string,
  referral: PropTypes.string,
  showCouponField: PropTypes.bool,
  forceCountry: PropTypes.string,
  showForceCountryField: PropTypes.bool,
  siteConfiguration: PropTypes.instanceOf(SiteConfiguration).isRequired,
};

SignupForm.defaultProps = {
  isLoading: false,
};

export default SignupForm;
