/* global App */

import { environment, oauthToken } from 'es6/services/window';
import cookies from 'es6/services/cookies';
import { setAPI as setMouseoverDictionaryAPI } from 'iknow-mouseover-dictionary/loader';

setMouseoverDictionaryAPI(
  '/api/sentence_dictionary',
  oauthToken() && { Authorization: `Bearer ${oauthToken()}` }
);

const Config = {
  transcodingSettings: {
    smallImage:       { width: 140, height: 140 },
    mediumImage:      { width: 200, height: 200 },
    largeImage:       { width: 260, height: 260 },
    appItemImage:     { width: 350, height: 350 },
    appSentenceImage: { width: 360, height: 330 },
  },

  isDevelopment: false,
  isTesting: false,

  logLevel: 'error',

  langSettings: {
    kanaMode: false,
    romajiMode: false,
  },

  oauthToken: null,

  showTranscriptions: false,

  user: {
    admin: false,
    isPremium: false,
    justRegistered: false,
    username: null,
    groupRestrictions: {
      canResetItemProgress: false,
    },
  },

  isLoggedIn() {
    return !!this.oauthToken;
  },

  /**
   * Checks the global namespace for variables passed in by
   * Rails templates.
   */
  refreshSettings() {
    // Update the user and API settings.
    if (typeof App === 'object' && App !== null) {
      const { Environment } = App;

      if (Environment) {
        const {
          API,
          isDevelopment,
          isTesting,
          User,
        } = Environment;

        this.isDevelopment = isDevelopment;
        this.isTesting = isTesting;

        if (API) {
          this.oauthToken = API.token;
        }

        if (User) {
          Object.assign(this.user, User);
        }
      }
    }
  },

  /**
   * Updates global config based on user settings.
   *
   * @param {UserSettings} userSettings
   * @param {Object} options
   * @param {Boolean} options.setKana   will update kana settings if set to true
   */
  updateSettings(userSettings, { setKana = false }) {
    const romajiMode = setKana && userSettings.isRomajiMode();
    const kanaMode = setKana && userSettings.isKanaMode();

    this.langSettings = { kanaMode, romajiMode };
    this.showTranscriptions = userSettings.apps.showTranscription;
  },

  getAssetHost: () => environment().assetHost || '',
  getAssetPath: () => environment().assetPath || '',
  getAssetCdns: () => environment().assetCdns || 1,

  getTranscoderHost: () => environment().transcoderHost || '',
  getTranscoderCdns: () => environment().transcoderCdns || 4,

  getReferrerURL: () => cookies.get('app_referer') || '/home',
};

Object.defineProperty(Config, 'locale', {
  get: () => document.documentElement.lang || 'en',
  set: () => {},
});

Object.defineProperty(Config, 'strings', {
  get: () => {
    try {
      const content = document.querySelector('#iknow-strings').content;
      return JSON.parse(content);
    } catch (e) {
      return {};
    }
  },
  set: () => {},
});

Config.refreshSettings();

export default Config;
