import Config from 'es6/core/config';
import Polyglot from 'node-polyglot';

const polyglot = new Polyglot({ phrases: Config.strings, locale: Config.locale });

export const extend = polyglot.extend.bind(polyglot);

export const locale = polyglot.locale.bind(polyglot);

export const t = polyglot.t.bind(polyglot);

export default polyglot;
