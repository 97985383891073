import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import saga from 'es6/redux/sagas/registration';
import dmm from 'es6/redux/modules/auth/dmm';
import logger from 'es6/redux/middleware/logger';
import env from 'es6/redux/modules/env';
import register from 'es6/redux/modules/auth/register';
import phone from 'es6/redux/modules/auth/phone';
import username from 'es6/redux/modules/auth/username';
import code from 'es6/redux/modules/auth/code';
import form from 'es6/redux/modules/auth/form';
import countryCode from 'es6/redux/modules/modals/country-code';

// Add all the authentication reducers.
const modals = combineReducers({ countryCode });
const auth = combineReducers({ dmm, register, phone, username, code, form });
export const reducers = combineReducers({ auth, env, modals });

const sagaMiddleware = createSagaMiddleware(saga);
export const middleware = [logger, thunk, sagaMiddleware];

export default () => createStore(reducers, compose(
  applyMiddleware(...middleware),
  window.devToolsExtension && process.env.NODE_ENV !== 'production' ?
    window.devToolsExtension() : f => f
));
