import { connect } from 'react-redux';
import BrandButton from 'es6/components/common/buttons/brand-button';
import { getForm } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const form = getForm(state).toJS();
  return { ...form };
}

export default connect(mapStateToProps)(BrandButton);
