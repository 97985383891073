/* eslint-disable no-shadow, no-param-reassign, prefer-template, brace-style, guard-for-in, no-restricted-syntax */
import { AjaxAbortedError, AjaxError, AjaxTimeoutError, NetworkError, TokenExpiredError, PaywallError } from 'es6/core/errors';
import { camelCaseKeys, underscoreKeys } from 'es6/helpers/case';

const CACHE_GENERATION_TIMEOUT = 2000;

/**
 * This class handles API calls to iKnow APIs and returns the appropriate response
 * based on what the APIs are expected to return.
 *
 * IMPORTANT NOTE: Some of these methods are used by the widgets and therefore
 * this class does not use any global variables (such as jQuery or underscore).
 */
export default class Ajax {
  constructor(pathBase, params = {}, options = {}) {
    options = Object.assign({
      headers: {},
      method: 'GET',
      withCredentials: false,
    }, options);

    this.xhr = new XMLHttpRequest();
    this._promise = new Promise((resolve, reject) => {
      this.xhr.onload = () => {
        // If there is no content, IE8 and IE9 will return a
        // 1223 status message instead of a 204.
        if (this.xhr.status === 1223) {
          resolve(null);
          return;
        }

        let response = this.xhr.responseText;
        try {
          // Parse JSON and CamelCase hash keys.
          response = sanitizeResponse(response);
        } catch (err) {
          // Response is not JSON, but a Raw ruby error.
          // Build out the response as best we can.
          response = {
            error: {
              message:   this.xhr.statusText,
              code:      this.xhr.status,
              exception: {
                // Use the response as it includes a backtrace (fyi, it also includes
                // some other stuff which we don't need, but is harmless).
                backtrace: response,
              },
            },
          };
        }

        if (this.xhr.status === 202) {
          // 'Cache Generation Started' on the server, so repeat the request.
          setTimeout(() => {
            new Ajax(pathBase, params, options)
              .then(resolve, reject);
          }, CACHE_GENERATION_TIMEOUT);
        } else if (this.xhr.status >= 200 && this.xhr.status < 300) {
          // Handle some form of success.
          resolve(response);
        } else if (this.xhr.status === 402) {
          reject(new PaywallError());
        } else {
          // Handle an error.
          let error;
          if (response.errorDescription) {
            // Old API style error. Format is {error:"invalid_token",error_description:"Given access token is invalid."}
            if (response.error === 'invalid_token') {
              error = new TokenExpiredError(
                response.errorDescription,
                this.xhr.status
              );
            } else {
              // Return a generic error.
              error = new Error(response.errorDescription);
            }
          } else {
            // APIv2 error (or raw ruby error formatted the same way).
            error = new AjaxError(
              response.error.message,
              response.error.code,
              response.error.exception,
              {
                pathBase,
                params,
                options,
              }
            );
          }
          reject(error);
        }
      };

      this.xhr.onabort = () => {
        reject(new AjaxAbortedError());
      };

      this.xhr.onerror = () => {
        // Status means the request was aborted. Modern browsers will just use
        // the onabort callback.
        if (this.xhr.status === 0) {
          reject(new AjaxAbortedError());
        } else {
          reject(new AjaxError(this.statusText));
        }
      };

      this.xhr.ontimeout = () => {
        reject(new AjaxTimeoutError());
      };

      // Certain network problems will throw an error when calling
      // send().
      try {
        if (options.method === 'GET') {
          const path = pathBase + '?' + preparePayload(params);

          this.xhr.open('GET', path, true);
          setOptions(this.xhr, options);
          this.xhr.send();
        } else {
          this.xhr.open(options.method, pathBase, true);
          setOptions(this.xhr, options);

          if (options.headers['Content-Type'] === 'application/json') {
            this.xhr.send(preparePayload(params, true));
          } else {
            this.xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            this.xhr.send(preparePayload(params, false));
          }
        }
      } catch (e) {
        reject(new NetworkError(e.message));
      }
    });
  }

  abort() {
    this.xhr.abort();
  }

  then(...args) {
    return this._promise.then(...args);
  }

  catch(...args) {
    return this._promise.catch(...args);
  }
}

/**
 * Converts an object to URL params. Should adhere to the same standards
 * and API that jQuery does: http://api.jquery.com/jquery.param/
 *
 * A lot of the code was structured after:
 * https://github.com/jquery/jquery/blob/2e10af143b7eafb7142524f6534a62aee1910bd1/src/ajax.js#L507
 *
 * @param {Object} obj
 * @returns {String}
 */
function params(obj) {
  const parameters = [];

  const add = (key, value) => {
    parameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
  };

  const buildParams = (prefix, obj) => {
    //  Handle the case for the array.
    if (Array.isArray(obj)) {
      obj.forEach((v, i) => {
        if (/\[\]$/.test(prefix)) {
          add(prefix, v);
        } else {
          const key = typeof v === 'object' || Array.isArray(v) ? i : '';
          buildParams(`${prefix}[${key}]`, v);
        }
      });
    }

    // Handle the case for an object.
    else if (obj !== null && typeof obj === 'object') {
      Object.keys(obj).forEach(key => buildParams(`${prefix}[${key}]`, obj[key]));
    }

    // Not an object, just encode as is.
    else {
      add(prefix, obj);
    }
  };

  Object.keys(obj).forEach(key => buildParams(key, obj[key]));

  return parameters.join('&');
}

/**
 * Prepares a payload to be sent to the server.
 *
 * @param {Object} data
 * @param {Boolean} stringify    true if the API isn't using application/json
 * @returns {String}
 */
function preparePayload(data, stringify = false) {
  const underscoredData = underscoreKeys(data);
  return stringify ? JSON.stringify(underscoredData) : params(underscoredData);
}

/**
 * Takes the response from the API and converts it to JSON with
 * camelCased keys.
 *
 * @param {String} responseText
 * @returns {Object}
 */
function sanitizeResponse(responseText) {
  return camelCaseKeys(JSON.parse(responseText));
}

/**
 * Sets options on the XHR object that were passed into the Ajax
 * instance.
 *
 * @param {XMLHttpRequest} xhr
 * @param {Object} options
 */
function setOptions(xhr, options) {
  if (options.timeout) {
    xhr.timeout = options.timeout;
  }

  if (options.withCredentials) {
    xhr.withCredentials = true;
  }

  for (const header in options.headers) {
    xhr.setRequestHeader(header, options.headers[header]);
  }
}
