import React from 'react';
import { t } from 'es6/services/localization';
import { StyleSheet, css } from 'aphrodite';
import ToggleLanguage from 'es6/containers/common/toggle-language';
import Glyph, { TRIANGLE_DOWN } from 'es6/components/common/glyph';
import { policy, privacy } from 'es6/core/paths';

function Footer({ children }) {
  return (
    <footer className={css(styles.footerTag)}>
      <div className={css(styles.childrenContainer)}>
        {children}
      </div>
      <div className={css(styles.footerContainer)}>
        <div className={css(styles.footer)}>
          <div className={css(styles.copyrightLanguageContainer)}>
            <div>
              <a href={privacy()} className={css(styles.aTag)}>
                <span>{t('footer.privacy policy')}</span>
              </a>
              <span>{' | '}</span>
              <a href={policy()} className={css(styles.aTag)}>
                <span>{t('footer.term of use')}</span>
              </a>
            </div>
            <div>
              {
                t('confirm-your-phone.copyright').split('\n').map((copyright, key) =>
                  <div key={key}>{copyright}</div>)
              }
            </div>
          </div>
          <div className={css(styles.glyphLanguageContainer)}>
            <Glyph type={TRIANGLE_DOWN} className={css(styles.triangleDown)} />
            <ToggleLanguage className={css(styles.toggleLanguage)} />
          </div>
        </div>
      </div>
    </footer>
  );
}

const styles = StyleSheet.create({
  footerTag: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  childrenContainer: {
    alignSelf: 'center',
    marginBottom: '3.5em',
  },
  footerContainer: {
    display: 'flex',
    marginTop: 'auto',
    width: '100%',
    minHeight: '70px',
    backgroundColor: '#E1E0D8',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap-reverse',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  copyrightLanguageContainer: {
    lineHeight: '2em',
    fontSize: '0.8em',
    textAlign: 'center',
    padding: '1em 2em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  aTag: {
    color: '#2F3D5A',
  },
  glyphLanguageContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    padding: '1em 2em 0 1em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  triangleDown: {
    fontSize: '1.2em',
    color: '#AAAAAA',
  },
  toggleLanguage: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    color: '#2F3D5A',
  },
});

Footer.propTypes = {
  children: React.PropTypes.node,
};

export default Footer;
