import { Record } from 'immutable';
import { createReducer, makeActionCreator } from 'es6/helpers/redux';

export const SET_COUNTRY = 'auth/phone/SET_COUNTRY';
export const SET_PHONE = 'auth/phone/SET_PHONE';

export const setCountry = makeActionCreator(SET_COUNTRY, 'country');
export const setPhone = makeActionCreator(SET_PHONE, 'phone');

const PhoneNumber = Record({
  country: '',
  phone: '',
});

const INITIAL_STATE = new PhoneNumber();

export default createReducer(INITIAL_STATE, {
  [SET_COUNTRY]: (state, { country }) => state.set('country', country),

  [SET_PHONE]: (state, { phone }) => state.set('phone', phone.replace(/[^0-9 ]/g, '')),
});
