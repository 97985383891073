import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';

export const BASE = 'base';

export const FOOTNOTE = 'footnote';

const COMMON = {
  color: '#2890D4',
  cursor: 'pointer',
  textDecoration: 'none',
};

const styles = StyleSheet.create({
  [BASE]: COMMON,

  [FOOTNOTE]: {
    ...COMMON,
    fontSize: '0.8em',
  },
});

function Link({ variant = BASE, ...rest }) {
  return (<a className={css(styles[variant])} {...rest} />);
}

Link.propTypes = {
  variant: PropTypes.oneOf([BASE, FOOTNOTE]),
};

export default Link;
