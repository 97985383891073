import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import PageContainer from 'es6/components/common/page-container';
import Header from 'es6/components/registration/common/header';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import FlashMessages from 'es6/containers/common/flash-messages';
import PhoneSection from 'es6/components/registration/authentication-box/phone-section';
import PhoneEnterResetCodeForm from 'es6/components/registration/forms/phone-enter-reset-code-form';
import PhoneResendCodeForm from 'es6/components/registration/forms/phone-resend-code-form';
import { generatePhoneResetCode } from 'es6/core/paths';
import Footer from 'es6/components/registration/common/footer';

function PhoneEnterResetCode(props) {
  const resetId = parseInt(props.params.resetId, 10);

  return (
    <PageContainer>
      <Header>
        <h1>{t('phone-number.reset-missing-password')}</h1>
      </Header>

      <AuthenticationBox>
        <PhoneSection>
          <div className={css(styles.formContainer)}>
            <FlashMessages />
            <PhoneResendCodeForm
              action={generatePhoneResetCode(resetId)}
            />
            <PhoneEnterResetCodeForm resetId={resetId} />
          </div>
        </PhoneSection>
      </AuthenticationBox>

      <Footer />
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    padding: '10px 0px 20px 0px',
    textAlign: 'center',
  },
});

PhoneEnterResetCode.propTypes = {
  params: PropTypes.object.isRequired,
};

export default PhoneEnterResetCode;
