import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dmmRegister as dmmRegisterActionCreator } from 'es6/redux/modules/auth/dmm';
import { dmmOauthRegister } from 'es6/core/paths';
import { t } from 'es6/services/localization';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import BrandButton from 'es6/containers/common/buttons/brand-button';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import EmailInput from 'es6/components/registration/inputs/email';
import FlashMessages from 'es6/containers/common/flash-messages';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import PasswordInput from 'es6/components/registration/inputs/password';
import PolicyLink from 'es6/components/registration/policy-link';
import { getRailsForm, getDmmForm } from 'es6/helpers/selectors';

function RegisterForm(props) {
  return (
    <AuthenticationForm action={dmmOauthRegister()} delay>
      <h5>{t('dmm-signup.register.header')}</h5>

      <FlashMessages />

      <CsrfTokenInput />
      <input type="hidden" name="token" value={props.token} />

      <InputContainer>
        <EmailInput />
      </InputContainer>

      <InputContainer>
        <PasswordInput />
      </InputContainer>

      <InputContainer>
        <BrandButton isLoading={props.isLoading} type="submit" >
          {t('dmm-signup.register.submit')}
        </BrandButton>
      </InputContainer>

      <PolicyLink>{t('dmm-signup.policy-link-text')}</PolicyLink>
    </AuthenticationForm>
  );
}

RegisterForm.propTypes = {
  dmmRegister: React.PropTypes.func,
  isLoading: React.PropTypes.bool,
  token: React.PropTypes.string,
};

RegisterForm.defaultProps = {
  isLoading: false,
};

function mapStateToProps(state) {
  const form = getRailsForm(state);
  const dmm = getDmmForm(state);
  return Object.assign(form.toJS(), dmm);
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ dmmRegister: dmmRegisterActionCreator }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
