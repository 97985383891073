import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import LinkForm from 'es6/components/registration/forms/link-form';
import AuthenticationForm from 'es6/components/registration/authentication-box/authentication-form';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';

class PhoneResendCodeForm extends LinkForm {
  render() {
    return (
      <AuthenticationForm
        isInline
        action={this.props.action}
        className={css(styles.form)}
      >
        <CsrfTokenInput />
        <a
          href=""
          onClick={this.submitForm}
        >
          {t('confirm-your-phone.send-code')}
        </a>
      </AuthenticationForm>
    );
  }
}

const styles = StyleSheet.create({
  form: {
    marginTop: '20px',
  },
});

export default PhoneResendCodeForm;
