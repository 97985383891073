import { connect } from 'react-redux';
import Signup from 'es6/components/pages/signup';
import { registerShowCouponField } from 'es6/redux/modules/auth/register';
import { getRegisterForm, getSiteConfiguration } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const register = getRegisterForm(state);
  return Object.assign({ siteConfiguration: getSiteConfiguration(state) }, register);
}

function mapDispatchToProps(dispatch) {
  return { onShowCouponField: () => dispatch(registerShowCouponField()) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
