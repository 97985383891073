import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import { session } from 'es6/core/paths';
import LinkForm from 'es6/components/registration/forms/link-form';
import AuthenticationForm from 'es6/components/registration/authentication-box/authentication-form';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';

class LogoutForm extends LinkForm {
  render() {
    return (
      <AuthenticationForm
        isInline
        action={session()}
        className={css(styles.form)}
      >
        <CsrfTokenInput />
        <input type="hidden" name="_method" value="delete" />

        <a
          href=""
          onClick={this.submitForm}
        >
          {t('logout')}
        </a>
      </AuthenticationForm>
    );
  }
}

const styles = StyleSheet.create({
  form: {
    marginTop: '20px',
  },
});

export default LogoutForm;
