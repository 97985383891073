/**
 * Taken straight from the redux reducing boilerplate article:
 * https://github.com/reactjs/redux/blob/master/docs/recipes/ReducingBoilerplate.md
 */
export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => (action[argNames[index]] = args[index]));
  return action;
};

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
};
