/* eslint-disable react/prefer-stateless-function, react/prop-types */
import React, { Component } from 'react';
import { dmmRedirect } from 'es6/core/paths';
import OauthButton from 'es6/components/registration/oauth/oauth-button';
import Providers from 'es6/core/oauth-providers';

/**
 * @param {Event} e
 */
function handleClick(e) {
  window.location = dmmRedirect();
}

class DmmOauthButton extends Component {
  render() {
    const { children } = this.props;

    return (
      <OauthButton provider={Providers.DMM} onClick={handleClick}>
        {children}
      </OauthButton>
    );
  }
}

export default DmmOauthButton;
