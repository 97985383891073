/* eslint-disable no-param-reassign, prefer-template */
import Ajax from 'es6/core/ajax';
import Config from 'es6/core/config';

function buildAjaxObject(path, params = {}, options = {}) {
  // Append oauth options if they are available.
  if (!params.oauthToken && Config.isLoggedIn()) {
    params.oauthToken = Config.oauthToken;
  }

  options = Object.assign({
    apiBase: '',
    headers: {},
    method: 'GET',
    withCredentials: false,
  }, options);

  if (options.headers['Content-Type'] === 'application/json' && params.oauthToken) {
    path += '?oauth_token=' + encodeURIComponent(params.oauthToken);
    delete params.oauthToken;
  }

  return new Ajax(options.apiBase + path, params, options);
}

/**
 * @params {Object} options
 * @returns {Object}
 */
function defaultPostOptions(options = {}) {
  return Object.assign({}, options, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

export function getGoal(goalId, params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/' + goalId, params, options);
}

export function getGoalSuggestion(languageCode, params = {}, options = {}) {
  params = Object.assign({ languageCode }, params);
  return buildAjaxObject('/api/v2/goals/suggest', params, options);
}

export function getUserAuthenticated(params = {}, options = {}) {
  return buildAjaxObject('/users/authenticated', params, options);
}

export function getUserCreatedGoals(params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/created', params, options);
}

export function getAggregateMemories(goalId, params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/' + goalId + '/memories/aggregate', params, options);
}

export function getIKnowCumulatives(params = {}, options = {}) {
  params = Object.assign({ applicationDomains: ['items', 'sentences'] }, params);
  return buildAjaxObject('/api/v2/statistics/iknow/cumulative', params, options);
}

export function getLearningEngineCumulatives(params = {}, options = {}) {
  params = Object.assign({ applicationDomains: ['items', 'sentences'] }, params);
  return buildAjaxObject('/api/v2/statistics/learning_engine/cumulative', params, options);
}

export function getGoalMemories(goalId, params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/' + goalId + '/memories', params, options);
}

export function getFocusedAggregateMemories(params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/focused/memories/aggregate', params, options);
}

export function getEnrolledAggregateMemories(params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/enrolled/memories/aggregate', params, options);
}

export function getGoalItemsBySeries(seriesId, params = {}, options = {}) {
  return buildAjaxObject(`/api/v2/series/${seriesId}/goal_items`, params, options);
}

export function getServerTime(params = {}, options = {}) {
  return buildAjaxObject('/api/v2/time', params, options);
}

export function getSettings(params = {}, options = {}) {
  return buildAjaxObject('/api/v2/settings', params, options);
}

export function getStudyAllData(language, params = {}, options = {}) {
  return buildAjaxObject('/api/v2/languages/' + language + '/study', params, options);
}

export function getStudyData(goalId, params = {}, options = {}) {
  return buildAjaxObject('/api/v2/goals/' + goalId + '/study', params, options);
}

export function getItem(itemId, params = {}, options = {}) {
  return buildAjaxObject(`/api/v2/items/${itemId}`, params, options);
}

export function getItemMemory(itemId, params = {}, options = {}) {
  return buildAjaxObject(`/api/v2/items/${itemId}/memory`, params, options);
}

export function getSiteConfiguration(params = {}, options = {}) {
  return buildAjaxObject('/api/v2/site_configuration', params, options);
}

export function getDiagnosticStudyData(diagId, params, options) {
  return buildAjaxObject('/api/v2/diagnostics/' + diagId + '/study', params, options);
}

export function getDiagnosticData(diagId, params = {}, options = {}) {
  return buildAjaxObject('/api/v2/diagnostics/' + diagId, params, options);
}

/**
 * Date should be specified as a parameter of format 'YYYY-MM-DD'.
 */
export function postAdminUserStreak(userId, params = {}, options = {}) {
  params = Object.assign({ date: 'YYYY-MM-DD' }, params);
  options = defaultPostOptions(options);

  return buildAjaxObject('/api/v2/admin/users/' + userId + '/streaks', params, options);
}

export function postFocusGoal(goalId, params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/goals/' + goalId + '/focus', params, options);
}

export function postUnfocusGoal(goalId, params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/goals/' + goalId + '/unfocus', params, options);
}

export function postEnrollGoal(goalId, params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/goals/' + goalId + '/enroll', params, options);
}

export function postUnenrollGoal(goalId, params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/goals/' + goalId + '/unenroll', params, options);
}

export function postGoal(params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/goals', params, options);
}

/**
 * Copys items from course to another.
 *
 * @param {Number} toGoalId
 */
export function postCopyItemsToCustomGoal(toGoalId, params = {}, options = {}) {
  params = Object.assign({ items: [] }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject(`/api/v2/goals/${toGoalId}/items`, params, options);
}

/**
 * Masters multiple items.
 *
 * @param {Number[]} itemIds
 */
export function postMemoryCheckpoint(itemIds = [], params = {}, options = {}) {
  params = Object.assign({ itemIds }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/memories/checkpoint', params, options);
}

export function postSettings(settings = {}, params = {}, options = {}) {
  params = Object.assign({ settings }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/settings', params, options);
}

/**
 * Resets multiple items.
 *
 * @param {Number[]} itemIds
 */
export function postResetItems(itemIds = [], params = {}, options = {}) {
  params = Object.assign({ itemIds }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/memories/destroy', params, options);
}


/**
 * Defers study of multiple items.
 *
 * @param {Number[]} itemIds
 */
export function postDeferItems(itemIds = [], params = {}, options = {}) {
  params = Object.assign({ itemIds }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/memories/defer', params, options);
}

/**
 * Skips multiple items.
 *
 * @param {Number[]} itemIds
 */
export function postSkipItems(itemIds = [], params = {}, options = {}) {
  params = Object.assign({ itemIds }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/memories/skip', params, options);
}

export function postSkipItem(itemId, params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/items/' + itemId + '/memory/skip', params, options);
}

/**
 * Unskips multiple items.
 *
 * @param {Number[]} itemIds
 */
export function postUnskipItems(itemIds = [], params = {}, options = {}) {
  params = Object.assign({ itemIds }, params);
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/memories/unskip', params, options);
}

export function postUnskipItem(itemId, params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/items/' + itemId + '/memory/unskip', params, options);
}

export function postSpecificContent(params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/study/specific_content', params, options);
}

export function postStudyData(params = {}, options = {}) {
  const timeout = Config.isDevelopment ? 0 : 30000;
  options = Object.assign(defaultPostOptions(options), { timeout });
  return buildAjaxObject('/api/v2/study', params, options);
}

export function postRefreshDmmAccount(params = {}, options = {}) {
  options = defaultPostOptions(options);
  return buildAjaxObject('/api/v2/dmm_accounts/refresh', params, options);
}
