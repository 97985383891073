import React, { PropTypes } from 'react';
import { listOf } from 'react-immutable-proptypes';
import { List } from 'immutable';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import { Country } from 'es6/core/countries';
import BasicModal from 'es6/components/common/modals/basic';
import Link from 'es6/components/common/link';

const styles = StyleSheet.create({
  container: {
    boxSizing: 'border-box',
    height: '80vh',
    width: '80vw',
    minWidth: '300px',
    maxWidth: '800px',
    padding: '1em',
    overflow: 'hidden',
  },

  input: {
    border: '1px solid #C1C0C2',
    borderRadius: '5px',
    boxSizing: 'border-box',
    fontSize: '1.4em',
    padding: '0.4em',
    width: '100%',
  },

  inputContainer: {
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    border: '1px solid #DDDDDD',
    borderRadius: '5px',
    padding: '1em',
    marginBottom: '1em',
  },

  table: {
    boxSizing: 'border-box',
    overflowY: 'auto',
    width: '100%',
  },

  tableContainer: {
    height: '67vh',
    overflowY: 'scroll',
  },

  th: {
    textAlign: 'left',
    padding: '0.6em 0.6em',
    fontSize: '1.2em',
    fontWeight: 'bold',
    backgroundColor: '#EBEBEB',
    borderBottom: '1px #DDDDDD solid',
    textTransform: 'uppercase',
    verticalAlign: 'middle',

    ':first-child': {
      borderTopLeftRadius: '5px',
      width: '70%',
    },
  },

  td: {
    fontSize: '1.2em',
    padding: '0.4em 0.6em',
    textAlign: 'left',
  },

  tr: {
    ':nth-child(odd)': {
      backgroundColor: '#F9F9F9',
    },
  },
});

function CountryCell({ country, onChooseCountry }) {
  const { native } = country;

  if (!onChooseCountry) {
    return (<span>{native}</span>);
  } else {
    const onClick = (e) => {
      e.preventDefault();
      onChooseCountry(country);
    };

    return (<Link href="" onClick={onClick}>{native}</Link>);
  }
}

CountryCell.propTypes = {
  country: PropTypes.instanceOf(Country),
  onChooseCountry: PropTypes.func,
};

class CountryCode extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      return true;
    }
    if (this.props.filter !== nextProps.filter) {
      return true;
    }
    if (!this.props.countries.equals(nextProps.countries)) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      this.filterInput.focus();
    }
  }

  onChange(e) {
    this.props.onFilter(e.target.value);
  }

  render() {
    return (
      <BasicModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={t('modals.country-code.title')}
        captureKeyEvents={false}
      >
        <div className={css(styles.container)}>
          <div className={css(styles.inputContainer)}>
            <input
              placeholder={t('modals.country-code.filter')}
              type="text"
              ref={(input) => { this.filterInput = input; }}
              onChange={this.onChange}
              value={this.props.filter}
              className={css(styles.input)}
            />
          </div>

          <div className={css(styles.tableContainer)}>
            <table className={css(styles.table)}>
              <thead>
                <tr>
                  <th className={css(styles.th)}>{t('modals.country-code.country')}</th>
                  <th className={css(styles.th)}>{t('modals.country-code.country-code')}</th>
                </tr>
              </thead>

              <tbody>
              {this.props.countries.map(country => (
                <tr key={country.isoCode} className={css(styles.tr)}>
                  <td className={css(styles.td)}>
                    <CountryCell country={country} onChooseCountry={this.props.onChooseCountry} />
                  </td>
                  <td className={css(styles.td)}>{country.countryCode}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </BasicModal>
    );
  }
}

CountryCode.propTypes = {
  countries: listOf(PropTypes.instanceOf(Country)),
  isOpen: PropTypes.bool,
  filter: PropTypes.string,
  onChooseCountry: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

CountryCode.defaultProps = {
  countries: List(),
};

export default CountryCode;
