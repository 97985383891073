import { Record } from 'immutable';
import { createReducer, makeActionCreator } from 'es6/helpers/redux';

export const SET_IS_LOADING = 'auth/form/SET_IS_LOADING';

export const setIsLoading = makeActionCreator(SET_IS_LOADING, 'isLoading');

const Form = Record({
  isLoading: false,
});

const INITIAL_STATE = new Form();

export default createReducer(INITIAL_STATE, {
  [SET_IS_LOADING]: (state, { isLoading }) => state.set('isLoading', isLoading),
});
