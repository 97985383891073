import React, { PropTypes } from 'react';
import { Country } from 'es6/core/countries';
import CountryCodeSelector from 'es6/components/registration/inputs/country-code-selector';
import CountryCode from 'es6/containers/registration/modals/country-code';
import PhoneInput from 'es6/components/registration/inputs/phone';

function CountryCodePhoneInput({
  country,
  name,
  phone,
  enableCountryCodeModal,
  openCountryCodeModal,
  onChooseCountry,
  onPhoneChange,
}) {
  return (
    <div>
      <CountryCodeSelector country={country} onClick={openCountryCodeModal} disabled={!enableCountryCodeModal} />
      {
        enableCountryCodeModal &&
          <CountryCode onChooseCountry={onChooseCountry} />
      }
      <PhoneInput onPhoneChange={onPhoneChange} value={phone} />
      <input name={name} type="hidden" value={`+${country.countryCode}${phone.replace(/[^0-9]/g, '')}`} />
    </div>
  );
}

CountryCodePhoneInput.propTypes = {
  country: PropTypes.instanceOf(Country).isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  enableCountryCodeModal: PropTypes.bool.isRequired,
  openCountryCodeModal: PropTypes.func,
  onChooseCountry: PropTypes.func,
  onPhoneChange: PropTypes.func,
};

export default CountryCodePhoneInput;
