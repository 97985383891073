import { takeEvery } from 'redux-saga';
import { OPEN_CLIENT } from 'es6/redux/modules/auth/oauth';

// Client window key for opening popups.
const CLIENT_WINDOW_KEY = 'IKNOW_OAUTH';

function* openClient(action) {
  const { height, width, path } = action;
  window.open(path, CLIENT_WINDOW_KEY, `width=${width},height=${height},resizable=yes`);
  window.focus();
}

export default function* watchRegistrationActions() {
  yield [
    takeEvery(OPEN_CLIENT, openClient),
  ];
}
