import React, { PropTypes } from 'react';

function PageContainer({ children }) {
  return (<section className="page-container">{children}</section>);
}

PageContainer.propTypes = {
  children: PropTypes.node,
};

export default PageContainer;
