import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Config from 'es6/core/config';
import CountryCodePhoneInput from 'es6/components/registration/inputs/country-code-phone';
import { open, close } from 'es6/redux/modules/modals/country-code';
import { setPhone, setCountry } from 'es6/redux/modules/auth/phone';
import { getSiteConfiguration, getPhone } from 'es6/helpers/selectors';
import COUNTRIES from 'es6/core/countries';

function mapStateToProps(state, ownProps) {
  const siteConfiguration = getSiteConfiguration(state);
  const phone = getPhone(state);
  let country = phone.country;

  if (!phone.country) {
    country = COUNTRIES.getCountryByIsoCode(siteConfiguration.isoCode);
  }

  let enableCountryCodeModal = true;

  const allowedCountryCodes = siteConfiguration && siteConfiguration.allowedCountryCodes;
  if (allowedCountryCodes && allowedCountryCodes.length === 1 && allowedCountryCodes[0] !== 'any') {
    enableCountryCodeModal = false;
  }

  const query = ownProps.location && ownProps.location.query;
  if (!!query && !!query.force_country && (Config.isDevelopment || Config.isTesting)) {
    const forcedCountry = COUNTRIES.getCountryByIsoCode(query.force_country);
    if (forcedCountry) {
      enableCountryCodeModal = true;
      if (!phone.country) {
        country = COUNTRIES.getCountryByIsoCode(query.force_country);
      }
    }
  }

  return { phone: phone.phone, country, enableCountryCodeModal };
}

function mapDispatchToProps(dispatch) {
  return {
    onPhoneChange: (phone) => dispatch(setPhone(phone)),
    openCountryCodeModal: () => dispatch(open()),
    onChooseCountry: (country) => {
      dispatch(setCountry(country));
      dispatch(close());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryCodePhoneInput));
