import React, { PropTypes } from 'react';
import BaseModal from 'es6/components/common/modals/base';

function CloseButton({ onClose }) {
  return (<i className="glyph glyph-cross-soft" onClick={onClose} />);
}

CloseButton.propTypes = {
  onClose: PropTypes.func,
};

/**
 * BaseModal with a title and a close button. If the title prop is not specified, will just add a
 * close button.
 *
 * The onClose prop will be called when either the close button is pressed or the modal overlay is
 * clicked.
 */
function BasicModal({ children, isOpen = false, captureKeyEvents = true, onClose, title }) {
  return (
    <BaseModal onOverlayClick={onClose} isOpen={isOpen} captureKeyEvents={captureKeyEvents} >
      <div className="basic-modal">
        <div className="close-btn">
          <CloseButton onClose={onClose} />
        </div>

        {title && <header><div className="title">{title}</div></header>}

        <section>
          {children}
        </section>
      </div>
    </BaseModal>
  );
}

BasicModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  captureKeyEvents: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default BasicModal;
