import { Record } from 'immutable';

export const PAYMENT_MODEL_FREE = 'Free';
export const BRANDING_ENGOO = 'Engoo';
export const BRANDING_IKNOW = 'iKnow';

const SiteConfiguration = Record({
  region: undefined,
  isoCode: undefined,
  branding: undefined,
  allowedCountryCodes: undefined,
  payment: {
    paymentModel: undefined,
    paymentMethod: undefined,
    b2b: false,
  },
  languages: {
    uiLanguages: [],
    defaultLanguage: undefined,
  },
  features: {
    contentSharing: false,
    contentCreation: false,
    qa: false,
    dmmIntegration: false,
    dictionary: false,
  },
  loginMethods: {
    defaultLoginMethod: undefined,
    dmm: false,
    email: false,
    phone: false,
  },
  registrationMethods: {
    dmm: false,
    email: false,
    phone: false,
  },
});

export default SiteConfiguration;
