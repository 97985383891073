/* eslint-disable no-console */

const LOG_LEVELS = { debug: 0, info: 1, warn: 2, error: 3 };

// Will always print logs that are this level or less.
const PRINT_LOG_LEVEL = LOG_LEVELS.warn;

// Colors for printing the logs.
const LEVEL_COLORS = { debug: '#ff1493', info: '#00008b', warn: '#ff8c00', error: '#ff0000' };
const TIMESTAMP_COLOR = '#000';
const NAMESPACE_COLOR = '#0000ff';

// Storage for the logs.
const logMessages = [];

/**
 * Creates and stores a log message (which can be dumped later).
 *
 * @param {String} logLevel    should reflect one of the keys in LOG_LEVELS
 * @param {String} namespace   describes what is being logged
 */
function log(logLevel, namespace, ...args) {
  const date = new Date();
  const timestamp = `${date.getHours()}:${date.getMinutes()}:${date.getMilliseconds()}`;
  const logMessage = { logLevel, namespace, args, timestamp };

  // Store the log message in case it needs to be dumped.
  logMessages.push(logMessage);

  // Print the log if it is above the designated print log level.
  if (LOG_LEVELS[logLevel] >= PRINT_LOG_LEVEL) {
    printLogMessage(logMessage);
  }
}

/**
 * [Log Level] [Date] [Namespace] arguments*
 * E.g. Logger.info("Learning App", "Fetching data.", self, foo, bar)
 * [info] [12:25:458] [Learning App] Fetching data. {Window...} "foo" "bar"
 */
function printLogMessage({ args, logLevel, namespace, timestamp }) {
  if (!(logLevel in console)) {
    console.log(...args);
  } else {
    console[logLevel](
      '%c[%s] %c[%s] %c[%s]',
      LEVEL_COLORS[logLevel],
      logLevel,
      TIMESTAMP_COLOR,
      timestamp,
      NAMESPACE_COLOR,
      namespace,
      ...args
    );
  }
}

/**
 * Prints all stored log messages.
 *
 * @param {String} logLevel
 */
export function dumpLogs(logLevel = 'warn') {
  if (!LOG_LEVELS.hasOwnProperty(logLevel)) {
    throw new TypeError('Invalid logger level');
  }

  logMessages.filter(logMessage => LOG_LEVELS[logMessage.logLevel] >= LOG_LEVELS[logLevel])
    .forEach(printLogMessage);
}

export const debug = log.bind(null, 'debug');
export const error = log.bind(null, 'error');
export const info = log.bind(null, 'info');
export const warn = log.bind(null, 'warn');

const Logger = Object.freeze({ dumpLogs, debug, info, error, warn });

// Expose to the window so users can dump data and for legacy support.
if (typeof window !== 'undefined') {
  window.Logger = Logger;
}

export default Logger;
