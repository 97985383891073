import { Record } from 'immutable';
import { createReducer, makeActionCreator } from 'es6/helpers/redux';

export const SET_CODE = 'auth/code/SET_CODE';

export const setCode = makeActionCreator(SET_CODE, 'code');

const Code = Record({
  code: '',
});

const INITIAL_STATE = new Code();

export default createReducer(INITIAL_STATE, {
  [SET_CODE]: (state, { code }) => state.set('code', code.replace(/[^0-9]/g, '')),
});
