import { connect } from 'react-redux';
import LoginForm from 'es6/components/registration/forms/login-form';
import { getSiteConfiguration } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const siteConfiguration = getSiteConfiguration(state);
  return Object.assign({}, { siteConfiguration });
}

export default connect(mapStateToProps)(LoginForm);
