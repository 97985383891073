import { connect } from 'react-redux';
import AuthenticationForm from 'es6/components/registration/authentication-box/authentication-form';
import { setIsLoading } from 'es6/redux/modules/auth/form';
import { getForm } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const form = getForm(state).toJS();
  return { ...form };
}

function mapDispatchToProps(dispatch) {
  return { setIsLoading: (isLoading) => dispatch(setIsLoading(isLoading)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationForm);
