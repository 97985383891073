import { connect } from 'react-redux';
import Username from 'es6/components/registration/inputs/username';
import { setInputMethod } from 'es6/redux/modules/auth/username';
import { getSiteConfiguration, getUsername } from 'es6/helpers/selectors';

function mapStateToProps(state) {
  const siteConfiguration = getSiteConfiguration(state);
  const username = getUsername(state);

  const loginMethods = siteConfiguration.loginMethods;
  const registrationMethods = siteConfiguration.registrationMethods;

  let defaultMethod;
  if (registrationMethods.email) {
    defaultMethod = 'email';
  } else if (registrationMethods.phone) {
    defaultMethod = 'phone';
  }

  const inputMethod = username.inputMethod || defaultMethod;
  const enabledChangeInputMethod = loginMethods.email && loginMethods.phone;

  return { inputMethod, enabledChangeInputMethod };
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeInputMethod: (inputMethod) => dispatch(setInputMethod(inputMethod)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Username);
