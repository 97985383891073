import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import Keys from 'es6/core/keys';

const CONTAINER_STYLE = {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: 100,
};

const OVERLAY_STYLE = {
  backgroundColor: 'rgba(85, 85, 85, 0.7)',
  cursor: 'pointer',
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
};

const CONTENT_STYLE = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  WebkitTransform: 'translate(-50%, -50%)',
};

/**
 * Renders the overlay and body for the modal.
 */
function ModalContainer({ children, onOverlayClick }) {
  return (
    <div style={CONTAINER_STYLE}>
      <div style={OVERLAY_STYLE} onClick={onOverlayClick} />
      <div style={CONTENT_STYLE}>{children}</div>
    </div>
  );
}

ModalContainer.propTypes = {
  children: PropTypes.node,
  onOverlayClick: PropTypes.func,
};

/**
 * Event handler to stop keyboard events from reaching the page underneath the
 * modal.
 */
function stopKeyEvents(event) {
  // Stop propagation of keyboard events to the app.
  event.stopPropagation();

  // Prevent the default tab key action so that the user can't use the tab key
  // to refocus the app and send key events to it.
  if (event.keyCode === Keys.TAB) {
    event.preventDefault();
  }
}

/**
 * Usage Example:
 *
 * <BaseModal isOpen={this.isOpenProperty} onOverlayClick={() => this.isOpenProperty = false}>
 *  <h1>Some text</h1>
 *  <div className="description">Some description</div>
 * </BaseModal>
 */
class BaseModal extends Component {
  constructor(props) {
    super(props);
    this._node = null;
  }

  componentDidMount() {
    const { props } = this;
    const { captureKeyEvents, isOpen } = props;

    this._node = document.createElement('div');
    document.body.appendChild(this._node);
    this.renderModal(props);

    if (captureKeyEvents) {
      // Give the container a tabIndex, so that it is focusable, so that it can
      // receive keyboard events.
      this._node.tabIndex = 0;

      // Capture keyboard events on the modal and stop propagation of the
      // events to the page underneath, so that the user can't use keyboard
      // keys to interact with the app while a modal is open.
      ['keydown', 'keyup', 'keypress'].forEach((event) => {
        this._node.addEventListener(event, stopKeyEvents);
      });

      // Focus the modal so it receives keyboard events.
      if (isOpen) {
        this._node.focus();
      }
    }
  }

  componentWillReceiveProps(newProps) {
    this.renderModal(newProps);

    if (newProps.isOpen && newProps.captureKeyEvents) {
      // Focus the modal so it receives keyboard events.
      this._node.focus();
    }
  }

  componentWillUnmount() {
    ['keydown', 'keyup', 'keypress'].forEach((event) => {
      this._node.removeEventListener(event, stopKeyEvents);
    });

    ReactDOM.unmountComponentAtNode(this._node);
    document.body.removeChild(this._node);
  }

  renderModal(props) {
    const { _node } = this;
    _node.style.display = props.isOpen ? 'block' : 'none';
    ReactDOM.render(<ModalContainer {...props} />, _node);
  }

  render() {
    return null;
  }
}

BaseModal.propTypes = {
  isOpen: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  // If true, stop propagation of key events (keydown, keyup, keypress) to the
  // page below the modal.
  captureKeyEvents: PropTypes.bool,
};

BaseModal.defaultProps = {
  isOpen: false,
  captureKeyEvents: true,
};

export default BaseModal;
