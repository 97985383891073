import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import { camelCaseKeys } from 'es6/helpers/case';
import createStore from 'es6/redux/stores/registration';
import { initEnv } from 'es6/redux/modules/env';
import LoginContainer from 'es6/containers/pages/login';
import SignupContainer from 'es6/containers/pages/signup';
import PhoneConfirmation from 'es6/components/pages/phone-confirmation';
import PhoneForgotPassword from 'es6/components/pages/phone-forgot-password';
import PhoneEnterResetCode from 'es6/components/pages/phone-enter-reset-code';
import DmmContainer from 'es6/containers/dmm/signup';
import GroupsInviteSignupContainer from 'es6/containers/groups/invite/signup';
import Config from 'es6/core/config';

const store = createStore();

let initiated = false;

/**
 * Takes variables from the env app and starts it up.
 *
 * @param {Object} env
 */
function initApp(env = {}) {
  if (!initiated) {
    initiated = true;

    // Only render the app once the loaded state is set.
    store.dispatch(initEnv({ ...camelCaseKeys(env), dispatchFlashMessages: true }))
         .then(() => ReactDOM.render(renderRouter(), document.querySelector('#primary-content')));
  }
}

function renderRouter() {
  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="/login" component={LoginContainer} />
        <Route path="/signup" component={SignupContainer} />
        <Route path="/dmm_oauth2/signup" component={DmmContainer} />
        <Route path="/groups/invite/signup" component={GroupsInviteSignupContainer} />
        <Route path="/referrals/:referral/signup" component={SignupContainer} />
        <Route path="/user_phone/confirmation" component={PhoneConfirmation} />
        <Route path="/user_phone/forgot_password" component={PhoneForgotPassword} />
        <Route path="/user_phone/enter_reset_code/:resetId" component={PhoneEnterResetCode} />
      </Router>
    </Provider>
  );
}

// Expose to the registration layout.
window.initApp = initApp;
