import React, { PropTypes } from 'react';
import { Map } from 'immutable';
import { StyleSheet, css } from 'aphrodite';

function Messages({ type, messages }) {
  const msg = messages instanceof Array ? messages : [messages];
  return (
    <div>
      {msg.map((m, i) =>
        <div key={i} className={css(styles[type])}>
          <span dangerouslySetInnerHTML={{ __html: m }} />
        </div>
      )}
    </div>
  );
}

Messages.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  type: PropTypes.string,
};

function FlashMessages({ flash }) {
  const error = flash.get('error');
  const notice = flash.get('notice');
  const warn = flash.get('warn');

  return (
    <div className="flash-messages">
      {notice && <Messages type="notice" messages={notice} />}
      {warn && <Messages type="warning" messages={warn} />}
      {error && <Messages type="error" messages={error} />}
    </div>
  );
}

FlashMessages.propTypes = {
  flash: PropTypes.instanceOf(Map).isRequired,
};

const styles = StyleSheet.create({
  notice: {
    color: '#0081bd',
  },
  warn: {
    color: '#c0ae13',
  },
  error: {
    color: '#c23000',
  },
});

export default FlashMessages;
