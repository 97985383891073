import { Record } from 'immutable';
import { createReducer, makeActionCreator } from 'es6/helpers/redux';

export const SET_INPUT_METHOD = 'auth/username/SET_INPUT_METHOD';

export const setInputMethod = makeActionCreator(SET_INPUT_METHOD, 'inputMethod');

const Username = Record({
  inputMethod: '',
});

const INITIAL_STATE = new Username();

export default createReducer(INITIAL_STATE, {
  [SET_INPUT_METHOD]: (state, { inputMethod }) => state.set('inputMethod', inputMethod),
});
