import React, { PropTypes } from 'react';
import { policy } from 'es6/core/paths';

function handleClick(e) {
  if (e.target.dataset.hasOwnProperty('policyLink')) {
    e.preventDefault();
    window.open(policy(), '_blank').focus();
  }
}

/**
 * Sadly, the string contains the link that is used to open the modal.
 * It should contain the data attribute 'policy-link.' Check that the
 * target contains that when triggering the registerShowPolicy event.
 */
function PolicyLink({ children }) {
  return (<span onClick={handleClick} dangerouslySetInnerHTML={{ __html: children }} />);
}

PolicyLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PolicyLink;
