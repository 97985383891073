import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import DmmOauthButton from 'es6/components/registration/oauth/dmm-oauth-button';
import OauthSection from 'es6/components/registration/authentication-box/oauth-section';
import NativeSection from 'es6/components/registration/authentication-box/native-section';
import PageContainer from 'es6/components/common/page-container';
import PolicyLink from 'es6/components/registration/policy-link';
import SiteConfiguration, { PAYMENT_MODEL_FREE } from 'es6/models/site-configuration';
import SignupForm from 'es6/containers/registration/forms/signup-form';
import Header from 'es6/components/registration/common/header';
import Footer from 'es6/components/registration/common/footer';
import BottomLink from 'es6/components/registration/common/bottom-link';

function ShowCouponLink({ onShowCouponField }) {
  const handleShowCouponField = (e) => {
    e.preventDefault();
    onShowCouponField();
  };

  return (<p><a href="" onClick={handleShowCouponField}>{t('signup.coupon-link')}</a></p>);
}

ShowCouponLink.propTypes = {
  onShowCouponField: PropTypes.func.isRequired,
};

function Signup({ onShowCouponField, siteConfiguration, showCouponField }) {
  const {
    payment: { paymentModel },
    registrationMethods: { dmm },
  } = siteConfiguration;
  const isFree = paymentModel === PAYMENT_MODEL_FREE;

  return (
    <PageContainer>
      <Header>
        <h1>{t('signup.header')}</h1>
      </Header>

      <AuthenticationBox>
        <NativeSection>
          <h5 className={css(styles.h5)}>
            {t('signup.label')}
          </h5>

          <SignupForm />

          <footer className="fine-print">
            {!isFree && !showCouponField &&
              <ShowCouponLink onShowCouponField={onShowCouponField} />}

            <p>
              <PolicyLink>{t('signup.policy-text')}</PolicyLink>
            </p>
          </footer>
        </NativeSection>

        {dmm &&
          <OauthSection>
            <div className="oauth-buttons">
              <div>
                <DmmOauthButton>{t('signup.dmm-button')}</DmmOauthButton>
                <p className="dmm-oauth-note">{t('signup.dmm-note')}</p>
              </div>
            </div>
          </OauthSection>
        }
      </AuthenticationBox>
      <BottomLink>
        <a href="/login">
          {t('signup.login-link')}
        </a>
      </BottomLink>

      <Footer />
    </PageContainer>
  );
}

Signup.propTypes = {
  onShowCouponField: PropTypes.func.isRequired,
  siteConfiguration: PropTypes.instanceOf(SiteConfiguration).isRequired,
  showCouponField: PropTypes.bool,
};

const styles = StyleSheet.create({
  h5: {
    marginBottom: '2em',
  },
});

export default Signup;
