/**
 * Uses js-cookie library. Reference for more details:
 * https://github.com/js-cookie/js-cookie
 *
 * Creates a special decoder to work with how rails encodes cookies.
 */
import Cookies from 'js-cookie';

const cookies = Cookies.withConverter((value, name) =>
  decodeURIComponent(value.replace(/\+/g, ' ')));

export default cookies;
