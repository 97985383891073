/* eslint-disable no-restricted-syntax, prefer-template */
/**
 * Helper method for camel casing keys and underscore keys in
 * an object.
 *
 * @param {Object|Array} obj
 * @param {Function} sanitizeHandler
 * @returns {Object}
 */
function sanitizeKeys(obj, sanitizeHandler) {
  // typeof null returns 'object'
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const isArray = Array.isArray(obj);
  const copy = isArray ? [] : {};

  for (const key in obj) {
    if (isArray && obj.hasOwnProperty(key)) {
      copy.push(sanitizeKeys(obj[key], sanitizeHandler));
    } else if (obj.hasOwnProperty(key)) {
      const newKey = sanitizeHandler(key);
      copy[newKey] = sanitizeKeys(obj[key], sanitizeHandler);
    }
  }

  return copy;
}

/**
 * Deep clones an object, transforming keys to be camcelcase.
 *
 * @param {Object} obj
 * @returns {Object}
 */
export function camelCaseKeys(obj) {
  return sanitizeKeys(obj, function (key) {
    return key.replace(/(_[a-zA-Z1-9])/g, function (match) {
      return match.toUpperCase().replace('_', '');
    });
  });
}

/**
 * Deep clones an object, transforming keys to be underscore.
 *
 * @param {Object} obj
 * @returns {Object}
 */
export function underscoreKeys(obj) {
  return sanitizeKeys(obj, function (key) {
    return key.replace(/([A-Z1-9])/g, function (match) {
      return '_' + match.toLowerCase();
    });
  });
}
