import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { getCsrfToken } from 'es6/helpers/selectors';

function CsrfTokenInput({ token = '' }) {
  return (<input type="hidden" name="authenticity_token" value={token} />);
}

CsrfTokenInput.propTypes = {
  token: PropTypes.string,
};

function mapStateToProps(state) {
  return { token: getCsrfToken(state) };
}

export default connect(mapStateToProps)(CsrfTokenInput);
