import React from 'react';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite';

function renderLoader(isLoading) {
  return (
    <img
      alt="loading"
      src="/_assets/icons/spinner.svg"
      className={classnames(
        css(styles.spinnerInitial),
        isLoading && css(styles.spinnerLoading))}
    />
  );
}

function BrandButton({ children, isLoading = false, onClick, type }) {
  const props = {
    onClick,
    type,
  };

  return (
    <button className={classnames(css(styles.brandButton), isLoading && css(styles.brandButtonIsLoading))} {...props}>
      {renderLoader(isLoading)}
      <span className={classnames(isLoading && css(styles.hideChildren))}>
        {children}
      </span>
    </button>
  );
}

BrandButton.propTypes = {
  children: React.PropTypes.node,
  isLoading: React.PropTypes.bool,
  onClick: React.PropTypes.func,
  type: React.PropTypes.string,
};

BrandButton.defaultProps = {
  isLoading: false,
};

const styles = StyleSheet.create({
  brandButton: {
    background: '#ff8b00',
    borderRadius: '5px',
    border: '0',
    boxShadow: '0px 2px 0px 0px rgba(0,0,0,0.20)',
    color: '#fff',
    cursor: 'pointer',
    position: 'relative',
    fontSize: '20px',
    lineHeight: '2.3',
    width: '100%',
    ':hover': {
      backgroundColor: '#e67d00',
    },
    ':focus': {
      backgroundColor: '#e67d00',
      outline: '0',
    },
    ':active': {
      backgroundColor: '#cc6f00',
      boxShadow: 'none',
    },
  },
  brandButtonIsLoading: {
    boxShadow: 'none',
    cursor: 'default',
  },
  /*
   *  Hiding the children instead of removing them will
   *  let the button keep its original dimensions.
   *  Otherwise, sudden dimenions changes can occur when
   *  removing the children and replacing them with the
   *  loading spinner, which would result in flickering
   *  for the user.
   */
  hideChildren: {
    visibility: 'hidden',
  },
  spinnerInitial: {
    /*
     *  Instead of a glyph a svg icon is used here instead.
     *  This is to mitigate the wobble issues that plague
     *  spinning font icons, see:
     *  https://github.com/FortAwesome/Font-Awesome/issues/671
     */
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    height: '24px',
    width: '24px',
    backgroundSize: '22px',
    position: 'absolute',
    visibility: 'hidden',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    transform: 'rotate(0.0turn)',
  },
  spinnerLoading: {
    visibility: 'visible',
    transform: 'rotate(180.0turn)',
    transition: 'transform 120000ms linear',
  },
});

export default BrandButton;
