import React from 'react';
import { StyleSheet, css } from 'aphrodite';

export default function Header({ children }) {
  return (
    <header className={css(styles.header)}>{children}</header>
  );
}

Header.propTypes = {
  children: React.PropTypes.node,
};

const styles = StyleSheet.create({
  header: {
    margin: '3.5em auto 0 auto',
    textAlign: 'center',
    padding: '0 2em',
  },
});

