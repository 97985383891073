import React, { PropTypes } from 'react';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite';
import Input from 'es6/components/registration/inputs/input';

const styles = StyleSheet.create({
  input: {
    '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: '0',
    },

    '-moz-appearance': 'textfield',
  },
  centerText: {
    textAlign: 'center',
  },
});

class CodeInput extends React.Component {
  constructor(props) {
    super(props);

    this.preventInvalidInput = this.preventInvalidInput.bind(this);
  }

  preventInvalidInput(e) {
    // Specifically prevent non-number characters from being entered
    // into the number input field
    // This is also to prevent a Microsoft Edge specific issue, see:
    // http://stackoverflow.com/questions/40941786/in-edge-placeholder-text-doesnt-disappear-after-entering-text-in-input-type-n

    if (!/\d|Enter$/.test(e.key) && !e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <Input
        type="number"
        pattern="\d*"
        autocomplete="nope"
        onChange={(e) => this.props.onCodeChange(e.target.value)}
        required
        onKeyPress={this.preventInvalidInput}
        className={classnames(css(styles.input), this.props.center && css(styles.centerText))}
        {...this.props}
      />
      );
  }
}

CodeInput.propTypes = {
  center: PropTypes.bool,
  onCodeChange: PropTypes.func,
};

CodeInput.defaultProps = {
  center: true,
};

export default CodeInput;
