import { Map, Record } from 'immutable';

const Language = Record({
  code: undefined,
  name: undefined,
  native: undefined,
});

export default Map({
  en: new Language({ name: 'English', native: 'English' }),
  ja: new Language({ name: 'Japanese', native: '日本語' }),
  'zh-Hans': new Language({ name: 'Simplified Chinese', native: '中文' }),
});
