import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { register } from 'es6/redux/modules/auth/register';
import { t } from 'es6/services/localization';
import { login, groupInviteSignup } from 'es6/core/paths';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import NativeSection from 'es6/components/registration/authentication-box/native-section';
import PageContainer from 'es6/components/common/page-container';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import BrandButton from 'es6/containers/common/buttons/brand-button';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import EmailInput from 'es6/components/registration/inputs/email';
import NameInput from 'es6/components/registration/inputs/name';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import FlashMessages from 'es6/containers/common/flash-messages';
import PasswordInput from 'es6/components/registration/inputs/password';
import PolicyLink from 'es6/components/registration/policy-link';
import Header from 'es6/components/registration/common/header';
import Footer from 'es6/components/registration/common/footer';
import { getRailsForm, getRegisterForm } from 'es6/helpers/selectors';

function GroupsInviteSignup({ email, name, isLoading = false, onSubmit }) {
  return (
    <PageContainer>
      <Header>
        <h1>{t('groups-invite.signup.header')}</h1>
        <h3>{t('groups-invite.signup.sub-header')}</h3>
      </Header>

      <AuthenticationBox>
        <NativeSection>
          <AuthenticationForm action={groupInviteSignup()} onSubmit={onSubmit}>
            <FlashMessages />

            <CsrfTokenInput />
            <InputContainer>
              <NameInput defaultValue={name} />
            </InputContainer>

            <InputContainer>
              <EmailInput name="user[email]" disabled={!!email} value={email} onChange={null} onInvalid={null} />
            </InputContainer>

            <InputContainer>
              <PasswordInput name="user[password]" />
            </InputContainer>

            <InputContainer>
              <BrandButton isLoading={isLoading} type="submit">
                {isLoading ? t('groups-invite.signup.submit-loading') : t('groups-invite.signup.submit')}
              </BrandButton>
            </InputContainer>
          </AuthenticationForm>

          <footer>
            <PolicyLink>{t('groups-invite.signup.policy-link-text')}</PolicyLink>
          </footer>
        </NativeSection>
      </AuthenticationBox>

      <Footer>
        <a href={login()}>{t('groups-invite.signup.login-link')}</a>
      </Footer>
    </PageContainer>
  );
}

GroupsInviteSignup.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const form = getRailsForm(state);
  const registerForm = getRegisterForm(state);
  return Object.assign(form.toJS(), registerForm);
}

function mapDispatchToProps(dispatch) {
  return { onSubmit: () => dispatch(register) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsInviteSignup);
