/* eslint-disable prefer-template */
export const AppPaths = Object.freeze({
  IKNOW: 'iknow',
  RAPID_CHOICE: 'rapid_choice',
  SELF_ASSESSMENT: 'self_check',
  SENTENCE_TRAINER: 'sentence_trainer',
});

export function course(goalId) {
  return `/courses/${goalId}`;
}

export function item(id, slug) {
  return `/items/${id}/${slug}`;
}

export function courseDirectory() {
  return '/content';
}

export function createCustomCourse() {
  return '/custom/courses/new';
}

export function customCourse(goalId) {
  return `/custom/courses/${goalId}`;
}

export function dmmOauthConnect() {
  return '/dmm_oauth2/connect';
}

export function dmmOauthRegister() {
  return '/dmm_oauth2/register';
}

export function dmmRedirect() {
  return '/dmm_oauth2/redirect';
}

export function locale(languageCode = '') {
  return `/locale?language_code=${languageCode}`;
}

export function forgotPassword() {
  return '/passwords/forgot';
}

export function forgotPasswordPhone() {
  return '/user_phone/forgot_password';
}

export function groupInviteSignup() {
  return '/groups/invite/signup';
}

export function login() {
  return '/login';
}

export const policy = () => '/policy';

export function payment(origin = '') {
  return `/payment?origin=${origin}`;
}

export function session() {
  return '/session';
}

export function series(seriesId) {
  return `/series/${seriesId}`;
}

export function signup() {
  return '/signup';
}

export function studySpecificContent() {
  return '/apps/specific_content';
}

export function studyAll(language, appPath = null) {
  return `/apps/study_all/${language}` + (appPath ? `/${appPath}` : '');
}

export function studyGoal(goalId, appPath = null) {
  return `/apps/courses/${goalId}` + (appPath ? `/${appPath}` : '');
}

export function widgetsAddToGoalButton() {
  return '/widgets/add_to_goal_button';
}

export function userAchievement(sharableIdentifier, achievementId) {
  return `/users/${sharableIdentifier}/achievements/${achievementId}`;
}

export function widgetsProxyFrame() {
  return '/widgets/proxy_frame';
}

export function confirmPhone() {
  return '/user_phone/confirm';
}

export function generatePhoneConfirmCode() {
  return '/user_phone/generate_confirm_code';
}

export function requestPhonePasswordReset() {
  return '/user_phone/request_password_reset';
}

export function resetPhonePassword(resetId) {
  return `/user_phone/reset_password/${resetId}`;
}

export function generatePhoneResetCode(resetId) {
  return `/user_phone/generate_reset_code/${resetId}`;
}

export function privacy() {
  return '/privacy';
}
