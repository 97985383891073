import React, { PropTypes } from 'react';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite';
import { Country } from 'es6/core/countries';
import Flag from 'es6/components/common/flag';
import Glyph, { TRIANGLE_DOWN } from 'es6/components/common/glyph';
import { t } from 'es6/services/localization';

const styles = StyleSheet.create({
  countryCodeSelector: {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
  setCursorPointer: {
    cursor: 'pointer',
  },
  setCursorDefault: {
    cursor: 'default',
  },
  flag: {
    width: '32px',
    height: '24px',
  },
  glyph: {
    fontSize: '24px',
  },
});

function CountryCodeSelector({
  country,
  onClick,
  disabled,
}) {
  return (
    <div
      onClick={!disabled && onClick}
      title={!disabled ? t('country-code-link') : ''}
      className={classnames(css(styles.countryCodeSelector), !disabled && css(styles.setCursorPointer))}
    >
      <Flag
        country={country}
        className={css(styles.flag)}
      />
      <Glyph
        type={TRIANGLE_DOWN}
        className={css(styles.glyph)}
      />
      <span>{`${country.native} (+${country.countryCode})`}</span>
    </div>
  );
}

CountryCodeSelector.propTypes = {
  country: PropTypes.instanceOf(Country).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CountryCodeSelector.defaultProps = {
  disabled: false,
};

export default CountryCodeSelector;
