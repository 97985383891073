import React, { PropTypes } from 'react';
import classnames from 'classnames';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  input: {
    border: '1px solid #C1C0C2',
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'block',
    fontSize: '16px',
    margin: '0 auto',
    padding: '1em',
    width: '100%',
  },
});

function Input(props) {
  const className = classnames(css(styles.input), props.className);
  return (<input {...props} className={className} />);
}

Input.propTypes = {
  className: PropTypes.string,
};

export default Input;
