import React from 'react';
import { StyleSheet, css } from 'aphrodite';

export default function NativeSection({ children }) {
  return (
    <section className={css(styles.nativeSection)}>
      <div className={css(styles.inner)}>
        {children}
      </div>
    </section>);
}

NativeSection.propTypes = {
  children: React.PropTypes.node,
};

const styles = StyleSheet.create({
  nativeSection: {
    padding: '2em 3em',
    backgroundColor: '#F6F5EF',
    lineHeight: '1.4',
    textAlign: 'center',
    flexBasis: 'calc(390px - 6em)',
  },
  inner: {
    maxWidth: '270px',
    margin: 'auto',
  },
});
