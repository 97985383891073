import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dmmConnect as dmmConnectActionCreator } from 'es6/redux/modules/auth/dmm';
import { dmmOauthConnect } from 'es6/core/paths';
import { t } from 'es6/services/localization';
import SiteConfiguration from 'es6/models/site-configuration';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import BrandButton from 'es6/containers/common/buttons/brand-button';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import UsernameInput from 'es6/containers/registration/inputs/username';
import FlashMessages from 'es6/containers/common/flash-messages';
import PasswordInput from 'es6/components/registration/inputs/password';
import { getRailsForm, getDmmForm, getSiteConfiguration } from 'es6/helpers/selectors';

function ConnectForm(props) {
  return (
    <AuthenticationForm action={dmmOauthConnect()} delay>
      <h5>{t('dmm-signup.connect.header')}</h5>

      <FlashMessages />

      <CsrfTokenInput />
      <input type="hidden" name="token" value={props.token} />

      <InputContainer>
        <UsernameInput
          name="login_user[email]"
        />
      </InputContainer>

      <InputContainer>
        <PasswordInput name="login_user[password]" />
      </InputContainer>

      <InputContainer>
        <BrandButton isLoading={props.isLoading} type="submit" >
          {t('dmm-signup.connect.submit')}
        </BrandButton>
      </InputContainer>
    </AuthenticationForm>
  );
}

ConnectForm.propTypes = {
  dmmConnect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  siteConfiguration: PropTypes.instanceOf(SiteConfiguration).isRequired,
  token: PropTypes.string,
};

ConnectForm.defaultProps = {
  isLoading: false,
};

function mapStateToProps(state) {
  const form = getRailsForm(state);
  const dmm = getDmmForm(state);
  const siteConfiguration = getSiteConfiguration(state);
  return { ...form.toJS(), ...dmm, siteConfiguration };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ dmmConnect: dmmConnectActionCreator }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectForm);
