import React from 'react';

export default class AuthenticationForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.delay && !prevProps.isLoading && this.props.isLoading) {
      // Introduce a slight delay, if requested, so the DOM has a chance to render
      // This is specifically needed for Safari, since it doesn't update the DOM
      // after a form submit happened
      setTimeout(function () {
        if (this.props.onSubmit) {
          this.props.onSubmit();
        } else {
          this.form.submit();
        }
      }.bind(this), 50);
    }
  }

  onSubmit(e) {
    if (!this.props.isLoading && this.props.setIsLoading) {
      this.props.setIsLoading(true);
    }

    if (this.props.delay) {
      e.preventDefault();
    } else if (this.props.onSubmit) {
      this.props.onSubmit(e);
      // form submit will be triggered automatically afterwards
      // if event is not canceled by onSubmit callback
    }
  }

  render() {
    return (
      <form
        ref={(form) => { this.form = form; }}
        method="POST"
        {...this.props}
        onSubmit={this.onSubmit}
      />
    );
  }
}

AuthenticationForm.propTypes = {
  delay: React.PropTypes.bool,
  isLoading: React.PropTypes.bool,
  setIsLoading: React.PropTypes.func,
  onSubmit: React.PropTypes.func,
};

AuthenticationForm.defaultProps = {
  delay: false,
  isLoading: false,
};
