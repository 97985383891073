import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { locale } from 'es6/core/paths';
import Languages from 'es6/core/languages';
import CsrfToken from 'es6/containers/registration/inputs/csrf-token';
import SiteConfiguration from 'es6/models/site-configuration';

/**
 * Clicking the link has to trigger a form post.
 */
function Language({ languageCode, classNameOverride }) {
  const url = locale(languageCode);

  const onClick = (e) => {
    e.preventDefault();
    e.target.childNodes[0].submit();
  };

  const finalClassName = classNameOverride || css(styles.toggleLanguage);

  return (
    <li>
      <a href={url} className={finalClassName} onClick={onClick}>
        <form action={url} method="POST" style={{ display: 'none' }}>
          <input type="input" name="_method" defaultValue="put" />
          <CsrfToken />
        </form>

        {Languages.getIn([languageCode, 'native'])}
      </a>
    </li>
  );
}

Language.propTypes = {
  languageCode: PropTypes.string.isRequired,
  classNameOverride: PropTypes.string,
};

function ToggleLanguage({ languageCode, className, siteConfiguration }) {
  const { languages: { defaultLanguage, uiLanguages } } = siteConfiguration;
  const current = languageCode || defaultLanguage;
  const languages = uiLanguages.filter(language => language !== current);

  return (
    <ul>
      {
        languages.map(
          language => <Language key={language} languageCode={language} classNameOverride={className} />
        )
      }
    </ul>
  );
}

ToggleLanguage.propTypes = {
  languageCode: PropTypes.string,
  className: PropTypes.string,
  siteConfiguration: PropTypes.instanceOf(SiteConfiguration).isRequired,
};

const styles = StyleSheet.create({
  toggleLanguage: {
    position: 'absolute',
    fontSize: '0.8em',
    top: '8em',
    left: '50%',
    transform: 'translateX(-50%)',
    marginBottom: '50px',
  },
});

export default ToggleLanguage;
