import React, { PropTypes } from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  inputContainer: {
    margin: '20px 0',
    textAlign: 'center',
  },
});

function InputContainer({ children }) {
  return (<div className={css(styles.inputContainer)}>{children}</div>);
}

InputContainer.propTypes = {
  children: PropTypes.node,
};

export default InputContainer;
