/* global window */

// This module exists that we can avoid referring global state in other places.

export function environment() {
  return window.App && window.App.Environment || {};
}

export function oauthToken() {
  const api = environment().API;
  return api ? api.token : null;
}

export const location = {
  getProtocol: () => window.location.protocol.replace(/:$/, ''),
  isConnectionSecure: () => location.getProtocol() === 'https',
};
