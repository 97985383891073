import React, { PropTypes } from 'react';
import { t } from 'es6/services/localization';
import { resetPhonePassword } from 'es6/core/paths';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import CodeInput from 'es6/containers/registration/inputs/code';
import PasswordInput from 'es6/components/registration/inputs/password';
import BrandButton from 'es6/containers/common/buttons/brand-button';

function PhoneEnterResetCodeForm(props) {
  return (
    <AuthenticationForm isInline action={resetPhonePassword(props.resetId)} delay>
      <CsrfTokenInput />

      <InputContainer>
        <CodeInput
          name="sms_code"
          placeholder={t('phone-number.reset-code')}
          center={false}
        />
      </InputContainer>

      <InputContainer>
        <PasswordInput
          name="new_password"
          placeholder={t('new password')}
        />
      </InputContainer>

      <InputContainer>
        <PasswordInput
          name="new_password_again"
          placeholder={t('confirm new password')}
        />
      </InputContainer>

      <InputContainer>
        <BrandButton type="submit">
          {t('confirm-your-phone.submit')}
        </BrandButton>
      </InputContainer>
    </AuthenticationForm>
  );
}

PhoneEnterResetCodeForm.propTypes = {
  resetId: PropTypes.number,
};

export default PhoneEnterResetCodeForm;
