// NREUM is a New Relic variable set by their third party script.

/* global NREUM */

/**
 * babel doesn't handle calls to the superclass constructor of
 * built in types, so do the same actions manually. Call this from
 * the constructor.
 *
 * TODO: Look into using https://github.com/loganfsmyth/babel-plugin-transform-builtin-extend
 *
 * constructor(...args) {
 *  super();
 *  constructorError.call(this, 'CustomError', ...args);
 * }
 *
 * @param {String} name       name of the exception
 * @param {String} message    message that gets passed into the exception
 */
export function constructError(name, message = '') {
  this.name = name;
  this.message = message;
  this.stack = (new Error()).stack;
}

export class AjaxAbortedError extends Error {
  constructor(...args) {
    super();
    constructError.call(this, 'AjaxAbortedError', ...args);
  }
}

export class AjaxError extends Error {
  constructor(message, code = null, exception = null, request = null) {
    super();
    constructError.call(this, 'AjaxError', message);

    this.code = code;
    this.request = request;

    // Also store any data related to server side errors.
    this.serverSideException = exception;
  }
}

export class PaywallError extends Error {
  constructor(...args) {
    super();
    constructError.call(this, 'PaywallError', ...args);
  }
}

export class NetworkError extends Error {
  constructor(...args) {
    super();
    constructError.call(this, 'NetworkError', ...args);
  }
}

export class TokenExpiredError extends Error {
  constructor(message = '', code = null) {
    super();
    constructError.call(this, 'TokenExpiredError', message);

    this.code = code;
  }
}

export class AjaxTimeoutError extends Error {
  constructor(...args) {
    super();
    constructError.call(this, 'AjaxTimeoutError', ...args);
  }
}

export class HaltFetchDataError extends Error {
  constructor(...args) {
    super();
    constructError.call(this, 'HaltFetchDataError', ...args);
  }
}

export class LoaderError extends Error {
  constructor(...args) {
    super();
    constructError.call(this, 'LoaderError', ...args);
  }
}

/**
 * Essentially a wrapper for the noticeError method. Will
 * send it to new relic (if available).
 *
 * @param {Error} error
 */
export function noticeError(error) {
  if (typeof NREUM !== 'undefined' && NREUM.noticeError) {
    NREUM.noticeError(error);
  }
}
