import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { t } from 'es6/services/localization';
import PageContainer from 'es6/components/common/page-container';
import Header from 'es6/components/registration/common/header';
import AuthenticationBox from 'es6/components/registration/authentication-box/authentication-box';
import PhoneSection from 'es6/components/registration/authentication-box/phone-section';
import PhoneForgotPasswordForm from 'es6/components/registration/forms/phone-forgot-password-form';
import Footer from 'es6/components/registration/common/footer';
import { login } from 'es6/core/paths';

function PhoneForgotPassword() {
  return (
    <PageContainer>
      <Header>
        <h1>{t('forgot your password?')}</h1>
        <h3>{t('phone-number.enter-current')}</h3>
      </Header>

      <AuthenticationBox>
        <PhoneSection>
          <div className={css(styles.formContainer)}>
            <PhoneForgotPasswordForm />
            <div>
              <a href={login()}>{t('phone-number.back-link')}</a>
            </div>
          </div>
        </PhoneSection>
      </AuthenticationBox>

      <Footer />
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    padding: '10px 0px 20px 0px',
    textAlign: 'center',
  },
});

export default PhoneForgotPassword;
