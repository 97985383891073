import classnames from 'classnames';
import React, { PropTypes } from 'react';
import { values } from 'lodash';
import { StyleSheet, css } from 'aphrodite';
import Providers from 'es6/core/oauth-providers';

function OauthButton({ isLoading, provider, onClick, children }) {
  const providers = {
    dmm: (
      <g id="DMM">
        <path d="M11.55,0H0V28H14c7,0,15.12-3.18,15.12-14S19.44,0,11.55,0Zm.8,18.29V9.56c2.11,0,5.34.1,5.34,4.34S14.58,18.29,12.35,18.29Z" fill="#010101" />
      </g>
    ),
  };

  return (
    <button className={classnames(css(styles.oauthButton), isLoading && css(styles.oauthButtonLoading))} onClick={onClick}>
      <div className={css(styles.iconWrapper)}>
        <svg
          className={css(styles.icon)}
          width="87px" height="28px" viewBox="0 0 87 28" version="1.1"
          xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          {providers[provider]}
        </svg>
      </div>
      <div className={classnames(css(styles.buttonText))}>{children}</div>
    </button>
  );
}

OauthButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  provider: PropTypes.oneOf(values(Providers)).isRequired,
  children: React.PropTypes.node,
};

OauthButton.defaultProps = {
  isLoading: false,
};

const styles = StyleSheet.create({
  oauthButton: {
    display: 'block',
    margin: '20px 0px',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E1E0D8',
    boxShadow: '2px 2px 0px 0px #E1E0D8',
    borderRadius: '2px',
    color: '#54616E',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '3.3',
    padding: '0 1.4em',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  oauthButtonLoading: {
    boxShadow: 'none',
    cursor: 'default',
  },
  iconWrapper: {
    display: 'inline-block',
    width: '33px',
    height: '33px',
  },
  icon: {
    display: 'inline-block',
    marginRight: '0.6em',
    verticalAlign: 'middle',
  },
  buttonText: {
    display: 'inline-block',
    width: 'calc(100% - 73px)',
    textAlign: 'center',
  },
});

export default OauthButton;
