import React from 'react';
import { t } from 'es6/services/localization';
import { session } from 'es6/core/paths';
import AuthenticationForm from 'es6/containers/registration/authentication-box/authentication-form';
import CsrfTokenInput from 'es6/containers/registration/inputs/csrf-token';
import InputContainer from 'es6/components/registration/authentication-box/input-container';
import UsernameInput from 'es6/containers/registration/inputs/username';
import FlashMessages from 'es6/containers/common/flash-messages';
import PasswordInput from 'es6/components/registration/inputs/password';
import BrandButton from 'es6/containers/common/buttons/brand-button';

function LoginForm() {
  return (
    <AuthenticationForm action={session()} delay>
      <FlashMessages />

      <CsrfTokenInput />

      <InputContainer>
        <UsernameInput name="user[email]" />
      </InputContainer>

      <InputContainer>
        <PasswordInput />
      </InputContainer>

      <InputContainer>
        <BrandButton type="submit">
          {t('login-form.submit')}
        </BrandButton>
      </InputContainer>
    </AuthenticationForm>
  );
}

export default LoginForm;
