import { List, Record } from 'immutable';

export class Country extends Record({
  countryCode: undefined,
  isoCode: undefined,
  name: undefined,
  native: undefined,
}) {
  /**
   * Checks if native or name matches the phrase.
   *
   * @param {String} phrase
   * @returns {Boolean}
   */
  contains(phrase) {
    return ['native', 'name'].filter(key => typeof this[key] === 'string')
                             .some(key => this[key].toLowerCase().search(phrase) !== -1);
  }
}

const COUNTRIES = List([
  new Country({
    name: 'Afghanistan',
    native: '‫افغانستان‬‎',
    isoCode: 'AF',
    countryCode: '93',
  }),
  new Country({
    name: 'Albania',
    native: 'Shqipëri',
    isoCode: 'AL',
    countryCode: '355',
  }),
  new Country({
    name: 'Algeria',
    native: '‫الجزائر‬‎',
    isoCode: 'DZ',
    countryCode: '213',
  }),
  new Country({
    name: 'American Samoa',
    native: 'American Samoa',
    isoCode: 'AS',
    countryCode: '1684',
  }),
  new Country({
    name: 'Andorra',
    native: 'Andorra',
    isoCode: 'AD',
    countryCode: '376',
  }),
  new Country({
    name: 'Angola',
    native: 'Angola',
    isoCode: 'AO',
    countryCode: '244',
  }),
  new Country({
    name: 'Anguilla',
    native: 'Anguilla',
    isoCode: 'AI',
    countryCode: '1264',
  }),
  new Country({
    name: 'Antigua and Barbuda',
    native: 'Antigua and Barbuda',
    isoCode: 'AG',
    countryCode: '1268',
  }),
  new Country({
    name: 'Argentina',
    native: 'Argentina',
    isoCode: 'AR',
    countryCode: '54',
  }),
  new Country({
    name: 'Armenia',
    native: 'Հայաստան',
    isoCode: 'AM',
    countryCode: '374',
  }),
  new Country({
    name: 'Aruba',
    native: 'Aruba',
    isoCode: 'AW',
    countryCode: '297',
  }),
  new Country({
    name: 'Australia',
    native: 'Australia',
    isoCode: 'AU',
    countryCode: '61',
  }),
  new Country({
    name: 'Austria',
    native: 'Österreich',
    isoCode: 'AT',
    countryCode: '43',
  }),
  new Country({
    name: 'Azerbaijan',
    native: 'Azərbaycan',
    isoCode: 'AZ',
    countryCode: '994',
  }),
  new Country({
    name: 'Bahamas',
    native: 'Bahamas',
    isoCode: 'BS',
    countryCode: '1242',
  }),
  new Country({
    name: 'Bahrain',
    native: '‫البحرين‬‎',
    isoCode: 'BH',
    countryCode: '973',
  }),
  new Country({
    name: 'Bangladesh',
    native: 'বাংলাদেশ',
    isoCode: 'BD',
    countryCode: '880',
  }),
  new Country({
    name: 'Barbados',
    native: 'Barbados',
    isoCode: 'BB',
    countryCode: '1246',
  }),
  new Country({
    name: 'Belarus',
    native: 'Беларусь',
    isoCode: 'BY',
    countryCode: '375',
  }),
  new Country({
    name: 'Belgium',
    native: 'België',
    isoCode: 'BE',
    countryCode: '32',
  }),
  new Country({
    name: 'Belize',
    native: 'Belize',
    isoCode: 'BZ',
    countryCode: '501',
  }),
  new Country({
    name: 'Benin',
    native: 'Bénin',
    isoCode: 'BJ',
    countryCode: '229',
  }),
  new Country({
    name: 'Bermuda',
    native: 'Bermuda',
    isoCode: 'BM',
    countryCode: '1441',
  }),
  new Country({
    name: 'Bhutan',
    native: 'འབྲུག',
    isoCode: 'BT',
    countryCode: '975',
  }),
  new Country({
    name: 'Bolivia',
    native: 'Bolivia',
    isoCode: 'BO',
    countryCode: '591',
  }),
  new Country({
    name: 'Bosnia and Herzegovina',
    native: 'Босна и Херцеговина',
    isoCode: 'BA',
    countryCode: '387',
  }),
  new Country({
    name: 'Botswana',
    native: 'Botswana',
    isoCode: 'BW',
    countryCode: '267',
  }),
  new Country({
    name: 'Brazil',
    native: 'Brasil',
    isoCode: 'BR',
    countryCode: '55',
  }),
  new Country({
    name: 'British Indian Ocean Territory',
    native: 'British Indian Ocean Territory',
    isoCode: 'IO',
    countryCode: '246',
  }),
  new Country({
    name: 'British Virgin Islands',
    native: 'British Virgin Islands',
    isoCode: 'VG',
    countryCode: '1284',
  }),
  new Country({
    name: 'Brunei',
    native: 'Brunei',
    isoCode: 'BN',
    countryCode: '673',
  }),
  new Country({
    name: 'Bulgaria',
    native: 'България',
    isoCode: 'BG',
    countryCode: '359',
  }),
  new Country({
    name: 'Burkina Faso',
    native: 'Burkina Faso',
    isoCode: 'BF',
    countryCode: '226',
  }),
  new Country({
    name: 'Burundi',
    native: 'Uburundi',
    isoCode: 'BI',
    countryCode: '257',
  }),
  new Country({
    name: 'Cambodia',
    native: 'កម្ពុជា',
    isoCode: 'KH',
    countryCode: '855',
  }),
  new Country({
    name: 'Cameroon',
    native: 'Cameroun',
    isoCode: 'CM',
    countryCode: '237',
  }),
  new Country({
    name: 'Canada',
    native: 'Canada',
    isoCode: 'CA',
    countryCode: '1',
  }),
  new Country({
    name: 'Cape Verde',
    native: 'Kabu Verdi',
    isoCode: 'CV',
    countryCode: '238',
  }),
  new Country({
    name: 'Caribbean Netherlands',
    native: 'Caribbean Netherlands',
    isoCode: 'BQ',
    countryCode: '599',
  }),
  new Country({
    name: 'Cayman Islands',
    native: 'Cayman Islands',
    isoCode: 'KY',
    countryCode: '1345',
  }),
  new Country({
    name: 'Central African Republic',
    native: 'République centrafricaine',
    isoCode: 'CF',
    countryCode: '236',
  }),
  new Country({
    name: 'Chad',
    native: 'Tchad',
    isoCode: 'TD',
    countryCode: '235',
  }),
  new Country({
    name: 'Chile',
    native: 'Chile',
    isoCode: 'CL',
    countryCode: '56',
  }),
  new Country({
    name: 'China',
    native: '中国',
    isoCode: 'CN',
    countryCode: '86',
  }),
  new Country({
    name: 'Christmas Island',
    native: 'Christmas Island',
    isoCode: 'CX',
    countryCode: '61',
  }),
  new Country({
    name: 'Cocos (Keeling) Islands',
    native: 'Cocos (Keeling) Islands',
    isoCode: 'CC',
    countryCode: '61',
  }),
  new Country({
    name: 'Colombia',
    native: 'Colombia',
    isoCode: 'CO',
    countryCode: '57',
  }),
  new Country({
    name: 'Comoros',
    native: '‫جزر القمر‬‎',
    isoCode: 'KM',
    countryCode: '269',
  }),
  new Country({
    name: 'Congo (DRC)',
    native: 'Jamhuri ya Kidemokrasia ya Kongo',
    isoCode: 'CD',
    countryCode: '243',
  }),
  new Country({
    name: 'Congo (Republic)',
    native: 'Congo-Brazzaville',
    isoCode: 'CG',
    countryCode: '242',
  }),
  new Country({
    name: 'Cook Islands',
    native: 'Cook Islands',
    isoCode: 'CK',
    countryCode: '682',
  }),
  new Country({
    name: 'Costa Rica',
    native: 'Costa Rica',
    isoCode: 'CR',
    countryCode: '506',
  }),
  new Country({
    name: 'Côte d’Ivoire',
    native: 'Côte d’Ivoire',
    isoCode: 'CI',
    countryCode: '225',
  }),
  new Country({
    name: 'Croatia',
    native: 'Hrvatska',
    isoCode: 'HR',
    countryCode: '385',
  }),
  new Country({
    name: 'Cuba',
    native: 'Cuba',
    isoCode: 'CU',
    countryCode: '53',
  }),
  new Country({
    name: 'Curaçao',
    native: 'Curaçao',
    isoCode: 'CW',
    countryCode: '599',
  }),
  new Country({
    name: 'Cyprus',
    native: 'Κύπρος',
    isoCode: 'CY',
    countryCode: '357',
  }),
  new Country({
    name: 'Czech Republic',
    native: 'Česká republika',
    isoCode: 'CZ',
    countryCode: '420',
  }),
  new Country({
    name: 'Denmark',
    native: 'Danmark',
    isoCode: 'DK',
    countryCode: '45',
  }),
  new Country({
    name: 'Djibouti',
    native: 'Djibouti',
    isoCode: 'DJ',
    countryCode: '253',
  }),
  new Country({
    name: 'Dominica',
    native: 'Dominica',
    isoCode: 'DM',
    countryCode: '1767',
  }),
  new Country({
    name: 'Dominican Republic',
    native: 'República Dominicana',
    isoCode: 'DO',
    countryCode: '1',
  }),
  new Country({
    name: 'Ecuador',
    native: 'Ecuador',
    isoCode: 'EC',
    countryCode: '593',
  }),
  new Country({
    name: 'Egypt',
    native: '‫مصر‬‎',
    isoCode: 'EG',
    countryCode: '20',
  }),
  new Country({
    name: 'El Salvador',
    native: 'El Salvador',
    isoCode: 'SV',
    countryCode: '503',
  }),
  new Country({
    name: 'Equatorial Guinea',
    native: 'Guinea Ecuatorial',
    isoCode: 'GQ',
    countryCode: '240',
  }),
  new Country({
    name: 'Eritrea',
    native: 'Eritrea',
    isoCode: 'ER',
    countryCode: '291',
  }),
  new Country({
    name: 'Estonia',
    native: 'Eesti',
    isoCode: 'EE',
    countryCode: '372',
  }),
  new Country({
    name: 'Ethiopia',
    native: 'Ethiopia',
    isoCode: 'ET',
    countryCode: '251',
  }),
  new Country({
    name: 'Falkland Islands',
    native: 'Islas Malvinas',
    isoCode: 'FK',
    countryCode: '500',
  }),
  new Country({
    name: 'Faroe Islands',
    native: 'Føroyar',
    isoCode: 'FO',
    countryCode: '298',
  }),
  new Country({
    name: 'Fiji',
    native: 'Fiji',
    isoCode: 'FJ',
    countryCode: '679',
  }),
  new Country({
    name: 'Finland',
    native: 'Suomi',
    isoCode: 'FI',
    countryCode: '358',
  }),
  new Country({
    name: 'France',
    native: 'France',
    isoCode: 'FR',
    countryCode: '33',
  }),
  new Country({
    name: 'French Guiana',
    native: 'Guyane française',
    isoCode: 'GF',
    countryCode: '594',
  }),
  new Country({
    name: 'French Polynesia',
    native: 'Polynésie française',
    isoCode: 'PF',
    countryCode: '689',
  }),
  new Country({
    name: 'Gabon',
    native: 'Gabon',
    isoCode: 'GA',
    countryCode: '241',
  }),
  new Country({
    name: 'Gambia',
    native: 'Gambia',
    isoCode: 'GM',
    countryCode: '220',
  }),
  new Country({
    name: 'Georgia',
    native: 'საქართველო',
    isoCode: 'GE',
    countryCode: '995',
  }),
  new Country({
    name: 'Germany',
    native: 'Deutschland',
    isoCode: 'DE',
    countryCode: '49',
  }),
  new Country({
    name: 'Ghana',
    native: 'Gaana',
    isoCode: 'GH',
    countryCode: '233',
  }),
  new Country({
    name: 'Gibraltar',
    native: 'Gibraltar',
    isoCode: 'GI',
    countryCode: '350',
  }),
  new Country({
    name: 'Greece',
    native: 'Ελλάδα',
    isoCode: 'GR',
    countryCode: '30',
  }),
  new Country({
    name: 'Greenland',
    native: 'Kalaallit Nunaat',
    isoCode: 'GL',
    countryCode: '299',
  }),
  new Country({
    name: 'Grenada',
    native: 'Grenada',
    isoCode: 'GD',
    countryCode: '1473',
  }),
  new Country({
    name: 'Guadeloupe',
    native: 'Guadeloupe',
    isoCode: 'GP',
    countryCode: '590',
  }),
  new Country({
    name: 'Guam',
    native: 'Guam',
    isoCode: 'GU',
    countryCode: '1671',
  }),
  new Country({
    name: 'Guatemala',
    native: 'Guatemala',
    isoCode: 'GT',
    countryCode: '502',
  }),
  new Country({
    name: 'Guernsey',
    native: 'Guernsey',
    isoCode: 'GG',
    countryCode: '44',
  }),
  new Country({
    name: 'Guinea',
    native: 'Guinée',
    isoCode: 'GN',
    countryCode: '224',
  }),
  new Country({
    name: 'Guinea-Bissau',
    native: 'Guiné Bissau',
    isoCode: 'GW',
    countryCode: '245',
  }),
  new Country({
    name: 'Guyana',
    native: 'Guyana',
    isoCode: 'GY',
    countryCode: '592',
  }),
  new Country({
    name: 'Haiti',
    native: 'Haiti',
    isoCode: 'HT',
    countryCode: '509',
  }),
  new Country({
    name: 'Honduras',
    native: 'Honduras',
    isoCode: 'HN',
    countryCode: '504',
  }),
  new Country({
    name: 'Hong Kong',
    native: '香港',
    isoCode: 'HK',
    countryCode: '852',
  }),
  new Country({
    name: 'Hungary',
    native: 'Magyarország',
    isoCode: 'HU',
    countryCode: '36',
  }),
  new Country({
    name: 'Iceland',
    native: 'Ísland',
    isoCode: 'IS',
    countryCode: '354',
  }),
  new Country({
    name: 'India',
    native: 'भारत',
    isoCode: 'IN',
    countryCode: '91',
  }),
  new Country({
    name: 'Indonesia',
    native: 'Indonesia',
    isoCode: 'ID',
    countryCode: '62',
  }),
  new Country({
    name: 'Iran',
    native: '‫ایران‬‎',
    isoCode: 'IR',
    countryCode: '98',
  }),
  new Country({
    name: 'Iraq',
    native: '‫العراق‬‎',
    isoCode: 'IQ',
    countryCode: '964',
  }),
  new Country({
    name: 'Ireland',
    native: 'Ireland',
    isoCode: 'IE',
    countryCode: '353',
  }),
  new Country({
    name: 'Isle of Man',
    native: 'Isle of Man',
    isoCode: 'IM',
    countryCode: '44',
  }),
  new Country({
    name: 'Israel',
    native: '‫ישראל‬‎',
    isoCode: 'IL',
    countryCode: '972',
  }),
  new Country({
    name: 'Italy',
    native: 'Italia',
    isoCode: 'IT',
    countryCode: '39',
  }),
  new Country({
    name: 'Jamaica',
    native: 'Jamaica',
    isoCode: 'JM',
    countryCode: '1876',
  }),
  new Country({
    name: 'Japan',
    native: '日本',
    isoCode: 'JP',
    countryCode: '81',
  }),
  new Country({
    name: 'Jersey',
    native: 'Jersey',
    isoCode: 'JE',
    countryCode: '44',
  }),
  new Country({
    name: 'Jordan',
    native: '‫الأردن‬‎',
    isoCode: 'JO',
    countryCode: '962',
  }),
  new Country({
    name: 'Kazakhstan',
    native: 'Казахстан',
    isoCode: 'KZ',
    countryCode: '7',
  }),
  new Country({
    name: 'Kenya',
    native: 'Kenya',
    isoCode: 'KE',
    countryCode: '254',
  }),
  new Country({
    name: 'Kiribati',
    native: 'Kiribati',
    isoCode: 'KI',
    countryCode: '686',
  }),
  new Country({
    name: 'Kosovo',
    native: 'Kosovo',
    isoCode: 'XK',
    countryCode: '383',
  }),
  new Country({
    name: 'Kuwait',
    native: '‫الكويت‬‎',
    isoCode: 'KW',
    countryCode: '965',
  }),
  new Country({
    name: 'Kyrgyzstan',
    native: 'Кыргызстан',
    isoCode: 'KG',
    countryCode: '996',
  }),
  new Country({
    name: 'Laos',
    native: 'ລາວ',
    isoCode: 'LA',
    countryCode: '856',
  }),
  new Country({
    name: 'Latvia',
    native: 'Latvija',
    isoCode: 'LV',
    countryCode: '371',
  }),
  new Country({
    name: 'Lebanon',
    native: '‫لبنان‬‎',
    isoCode: 'LB',
    countryCode: '961',
  }),
  new Country({
    name: 'Lesotho',
    native: 'Lesotho',
    isoCode: 'LS',
    countryCode: '266',
  }),
  new Country({
    name: 'Liberia',
    native: 'Liberia',
    isoCode: 'LR',
    countryCode: '231',
  }),
  new Country({
    name: 'Libya',
    native: '‫ليبيا‬‎',
    isoCode: 'LY',
    countryCode: '218',
  }),
  new Country({
    name: 'Liechtenstein',
    native: 'Liechtenstein',
    isoCode: 'LI',
    countryCode: '423',
  }),
  new Country({
    name: 'Lithuania',
    native: 'Lietuva',
    isoCode: 'LT',
    countryCode: '370',
  }),
  new Country({
    name: 'Luxembourg',
    native: 'Luxembourg',
    isoCode: 'LU',
    countryCode: '352',
  }),
  new Country({
    name: 'Macau',
    native: '澳門',
    isoCode: 'MO',
    countryCode: '853',
  }),
  new Country({
    name: 'Macedonia (FYROM)',
    native: 'Македонија',
    isoCode: 'MK',
    countryCode: '389',
  }),
  new Country({
    name: 'Madagascar',
    native: 'Madagasikara',
    isoCode: 'MG',
    countryCode: '261',
  }),
  new Country({
    name: 'Malawi',
    native: 'Malawi',
    isoCode: 'MW',
    countryCode: '265',
  }),
  new Country({
    name: 'Malaysia',
    native: 'Malaysia',
    isoCode: 'MY',
    countryCode: '60',
  }),
  new Country({
    name: 'Maldives',
    native: 'Maldives',
    isoCode: 'MV',
    countryCode: '960',
  }),
  new Country({
    name: 'Mali',
    native: 'Mali',
    isoCode: 'ML',
    countryCode: '223',
  }),
  new Country({
    name: 'Malta',
    native: 'Malta',
    isoCode: 'MT',
    countryCode: '356',
  }),
  new Country({
    name: 'Marshall Islands',
    native: 'Marshall Islands',
    isoCode: 'MH',
    countryCode: '692',
  }),
  new Country({
    name: 'Martinique',
    native: 'Martinique',
    isoCode: 'MQ',
    countryCode: '596',
  }),
  new Country({
    name: 'Mauritania',
    native: '‫موريتانيا‬‎',
    isoCode: 'MR',
    countryCode: '222',
  }),
  new Country({
    name: 'Mauritius',
    native: 'Moris',
    isoCode: 'MU',
    countryCode: '230',
  }),
  new Country({
    name: 'Mayotte',
    native: 'Mayotte',
    isoCode: 'YT',
    countryCode: '262',
  }),
  new Country({
    name: 'Mexico',
    native: 'México',
    isoCode: 'MX',
    countryCode: '52',
  }),
  new Country({
    name: 'Micronesia',
    native: 'Micronesia',
    isoCode: 'FM',
    countryCode: '691',
  }),
  new Country({
    name: 'Moldova',
    native: 'Republica Moldova',
    isoCode: 'MD',
    countryCode: '373',
  }),
  new Country({
    name: 'Monaco',
    native: 'Monaco',
    isoCode: 'MC',
    countryCode: '377',
  }),
  new Country({
    name: 'Mongolia',
    native: 'Монгол',
    isoCode: 'MN',
    countryCode: '976',
  }),
  new Country({
    name: 'Montenegro',
    native: 'Crna Gora',
    isoCode: 'ME',
    countryCode: '382',
  }),
  new Country({
    name: 'Montserrat',
    native: 'Montserrat',
    isoCode: 'MS',
    countryCode: '1664',
  }),
  new Country({
    name: 'Morocco',
    native: '‫المغرب‬‎',
    isoCode: 'MA',
    countryCode: '212',
  }),
  new Country({
    name: 'Mozambique',
    native: 'Moçambique',
    isoCode: 'MZ',
    countryCode: '258',
  }),
  new Country({
    name: 'Myanmar (Burma)',
    native: 'မြန်မာ',
    isoCode: 'MM',
    countryCode: '95',
  }),
  new Country({
    name: 'Namibia',
    native: 'Namibië',
    isoCode: 'NA',
    countryCode: '264',
  }),
  new Country({
    name: 'Nauru',
    native: 'Nauru',
    isoCode: 'NR',
    countryCode: '674',
  }),
  new Country({
    name: 'Nepal',
    native: 'नेपाल',
    isoCode: 'NP',
    countryCode: '977',
  }),
  new Country({
    name: 'Netherlands',
    native: 'Nederland',
    isoCode: 'NL',
    countryCode: '31',
  }),
  new Country({
    name: 'New Caledonia',
    native: 'Nouvelle-Calédonie',
    isoCode: 'NC',
    countryCode: '687',
  }),
  new Country({
    name: 'New Zealand',
    native: 'New Zealand',
    isoCode: 'NZ',
    countryCode: '64',
  }),
  new Country({
    name: 'Nicaragua',
    native: 'Nicaragua',
    isoCode: 'NI',
    countryCode: '505',
  }),
  new Country({
    name: 'Niger',
    native: 'Nijar',
    isoCode: 'NE',
    countryCode: '227',
  }),
  new Country({
    name: 'Nigeria',
    native: 'Nigeria',
    isoCode: 'NG',
    countryCode: '234',
  }),
  new Country({
    name: 'Niue',
    native: 'Niue',
    isoCode: 'NU',
    countryCode: '683',
  }),
  new Country({
    name: 'Norfolk Island',
    native: 'Norfolk Island',
    isoCode: 'NF',
    countryCode: '672',
  }),
  new Country({
    name: 'North Korea',
    native: '조선 민주주의 인민 공화국',
    isoCode: 'KP',
    countryCode: '850',
  }),
  new Country({
    name: 'Northern Mariana Islands',
    native: 'Northern Mariana Islands',
    isoCode: 'MP',
    countryCode: '1670',
  }),
  new Country({
    name: 'Norway',
    native: 'Norge',
    isoCode: 'NO',
    countryCode: '47',
  }),
  new Country({
    name: 'Oman',
    native: '‫عُمان‬‎',
    isoCode: 'OM',
    countryCode: '968',
  }),
  new Country({
    name: 'Pakistan',
    native: '‫پاکستان‬‎',
    isoCode: 'PK',
    countryCode: '92',
  }),
  new Country({
    name: 'Palau',
    native: 'Palau',
    isoCode: 'PW',
    countryCode: '680',
  }),
  new Country({
    name: 'Palestine',
    native: '‫فلسطين‬‎',
    isoCode: 'PS',
    countryCode: '970',
  }),
  new Country({
    name: 'Panama',
    native: 'Panamá',
    isoCode: 'PA',
    countryCode: '507',
  }),
  new Country({
    name: 'Papua New Guinea',
    native: 'Papua New Guinea',
    isoCode: 'PG',
    countryCode: '675',
  }),
  new Country({
    name: 'Paraguay',
    native: 'Paraguay',
    isoCode: 'PY',
    countryCode: '595',
  }),
  new Country({
    name: 'Peru',
    native: 'Perú',
    isoCode: 'PE',
    countryCode: '51',
  }),
  new Country({
    name: 'Philippines',
    native: 'Philippines',
    isoCode: 'PH',
    countryCode: '63',
  }),
  new Country({
    name: 'Poland',
    native: 'Polska',
    isoCode: 'PL',
    countryCode: '48',
  }),
  new Country({
    name: 'Portugal',
    native: 'Portugal',
    isoCode: 'PT',
    countryCode: '351',
  }),
  new Country({
    name: 'Puerto Rico',
    native: 'Puerto Rico',
    isoCode: 'PR',
    countryCode: '1',
  }),
  new Country({
    name: 'Qatar',
    native: '‫قطر‬‎',
    isoCode: 'QA',
    countryCode: '974',
  }),
  new Country({
    name: 'Réunion',
    native: 'La Réunion',
    isoCode: 'RE',
    countryCode: '262',
  }),
  new Country({
    name: 'Romania',
    native: 'România',
    isoCode: 'RO',
    countryCode: '40',
  }),
  new Country({
    name: 'Russia',
    native: 'Россия',
    isoCode: 'RU',
    countryCode: '7',
  }),
  new Country({
    name: 'Rwanda',
    native: 'Rwanda',
    isoCode: 'RW',
    countryCode: '250',
  }),
  new Country({
    name: 'Saint Barthélemy',
    native: 'Saint-Barthélemy',
    isoCode: 'BL',
    countryCode: '590',
  }),
  new Country({
    name: 'Saint Helena',
    native: 'Saint Helena',
    isoCode: 'SH',
    countryCode: '290',
  }),
  new Country({
    name: 'Saint Kitts and Nevis',
    native: 'Saint Kitts and Nevis',
    isoCode: 'KN',
    countryCode: '1869',
  }),
  new Country({
    name: 'Saint Lucia',
    native: 'Saint Lucia',
    isoCode: 'LC',
    countryCode: '1758',
  }),
  new Country({
    name: 'Saint Martin (Saint-Martin (partie française))',
    native: 'Saint Martin (Saint-Martin (partie française))',
    isoCode: 'MF',
    countryCode: '590',
  }),
  new Country({
    name: 'Saint Pierre and Miquelon',
    native: 'Saint-Pierre-et-Miquelon',
    isoCode: 'PM',
    countryCode: '508',
  }),
  new Country({
    name: 'Saint Vincent and the Grenadines',
    native: 'Saint Vincent and the Grenadines',
    isoCode: 'VC',
    countryCode: '1784',
  }),
  new Country({
    name: 'Samoa',
    native: 'Samoa',
    isoCode: 'WS',
    countryCode: '685',
  }),
  new Country({
    name: 'San Marino',
    native: 'San Marino',
    isoCode: 'SM',
    countryCode: '378',
  }),
  new Country({
    name: 'São Tomé and Príncipe',
    native: 'São Tomé e Príncipe',
    isoCode: 'ST',
    countryCode: '239',
  }),
  new Country({
    name: 'Saudi Arabia',
    native: '‫المملكة العربية السعودية‬‎',
    isoCode: 'SA',
    countryCode: '966',
  }),
  new Country({
    name: 'Senegal',
    native: 'Sénégal',
    isoCode: 'SN',
    countryCode: '221',
  }),
  new Country({
    name: 'Serbia',
    native: 'Србија',
    isoCode: 'RS',
    countryCode: '381',
  }),
  new Country({
    name: 'Seychelles',
    native: 'Seychelles',
    isoCode: 'SC',
    countryCode: '248',
  }),
  new Country({
    name: 'Sierra Leone',
    native: 'Sierra Leone',
    isoCode: 'SL',
    countryCode: '232',
  }),
  new Country({
    name: 'Singapore',
    native: 'Singapore',
    isoCode: 'SG',
    countryCode: '65',
  }),
  new Country({
    name: 'Sint Maarten',
    native: 'Sint Maarten',
    isoCode: 'SX',
    countryCode: '1721',
  }),
  new Country({
    name: 'Slovakia',
    native: 'Slovensko',
    isoCode: 'SK',
    countryCode: '421',
  }),
  new Country({
    name: 'Slovenia',
    native: 'Slovenija',
    isoCode: 'SI',
    countryCode: '386',
  }),
  new Country({
    name: 'Solomon Islands',
    native: 'Solomon Islands',
    isoCode: 'SB',
    countryCode: '677',
  }),
  new Country({
    name: 'Somalia',
    native: 'Soomaaliya',
    isoCode: 'SO',
    countryCode: '252',
  }),
  new Country({
    name: 'South Africa',
    native: 'South Africa',
    isoCode: 'ZA',
    countryCode: '27',
  }),
  new Country({
    name: 'South Korea',
    native: '대한민국',
    isoCode: 'KR',
    countryCode: '82',
  }),
  new Country({
    name: 'South Sudan',
    native: '‫جنوب السودان‬‎',
    isoCode: 'SS',
    countryCode: '211',
  }),
  new Country({
    name: 'Spain',
    native: 'España',
    isoCode: 'ES',
    countryCode: '34',
  }),
  new Country({
    name: 'Sri Lanka',
    native: 'ශ්‍රී ලංකාව',
    isoCode: 'LK',
    countryCode: '94',
  }),
  new Country({
    name: 'Sudan',
    native: '‫السودان‬‎',
    isoCode: 'SD',
    countryCode: '249',
  }),
  new Country({
    name: 'Suriname',
    native: 'Suriname',
    isoCode: 'SR',
    countryCode: '597',
  }),
  new Country({
    name: 'Svalbard and Jan Mayen',
    native: 'Svalbard and Jan Mayen',
    isoCode: 'SJ',
    countryCode: '47',
  }),
  new Country({
    name: 'Swaziland',
    native: 'Swaziland',
    isoCode: 'SZ',
    countryCode: '268',
  }),
  new Country({
    name: 'Sweden',
    native: 'Sverige',
    isoCode: 'SE',
    countryCode: '46',
  }),
  new Country({
    name: 'Switzerland',
    native: 'Schweiz',
    isoCode: 'CH',
    countryCode: '41',
  }),
  new Country({
    name: 'Syria',
    native: '‫سوريا‬‎',
    isoCode: 'SY',
    countryCode: '963',
  }),
  new Country({
    name: 'Taiwan',
    native: '台灣',
    isoCode: 'TW',
    countryCode: '886',
  }),
  new Country({
    name: 'Tajikistan',
    native: 'Tajikistan',
    isoCode: 'TJ',
    countryCode: '992',
  }),
  new Country({
    name: 'Tanzania',
    native: 'Tanzania',
    isoCode: 'TZ',
    countryCode: '255',
  }),
  new Country({
    name: 'Thailand',
    native: 'ไทย',
    isoCode: 'TH',
    countryCode: '66',
  }),
  new Country({
    name: 'Timor-Leste',
    native: 'Timor-Leste',
    isoCode: 'TL',
    countryCode: '670',
  }),
  new Country({
    name: 'Togo',
    native: 'Togo',
    isoCode: 'TG',
    countryCode: '228',
  }),
  new Country({
    name: 'Tokelau',
    native: 'Tokelau',
    isoCode: 'TK',
    countryCode: '690',
  }),
  new Country({
    name: 'Tonga',
    native: 'Tonga',
    isoCode: 'TO',
    countryCode: '676',
  }),
  new Country({
    name: 'Trinidad and Tobago',
    native: 'Trinidad and Tobago',
    isoCode: 'TT',
    countryCode: '1868',
  }),
  new Country({
    name: 'Tunisia',
    native: '‫تونس‬‎',
    isoCode: 'TN',
    countryCode: '216',
  }),
  new Country({
    name: 'Turkey',
    native: 'Türkiye',
    isoCode: 'TR',
    countryCode: '90',
  }),
  new Country({
    name: 'Turkmenistan',
    native: 'Turkmenistan',
    isoCode: 'TM',
    countryCode: '993',
  }),
  new Country({
    name: 'Turks and Caicos Islands',
    native: 'Turks and Caicos Islands',
    isoCode: 'TC',
    countryCode: '1649',
  }),
  new Country({
    name: 'Tuvalu',
    native: 'Tuvalu',
    isoCode: 'TV',
    countryCode: '688',
  }),
  new Country({
    name: 'U.S. Virgin Islands',
    native: 'U.S. Virgin Islands',
    isoCode: 'VI',
    countryCode: '1340',
  }),
  new Country({
    name: 'Uganda',
    native: 'Uganda',
    isoCode: 'UG',
    countryCode: '256',
  }),
  new Country({
    name: 'Ukraine',
    native: 'Україна',
    isoCode: 'UA',
    countryCode: '380',
  }),
  new Country({
    name: 'United Arab Emirates',
    native: '‫الإمارات العربية المتحدة‬‎',
    isoCode: 'AE',
    countryCode: '971',
  }),
  new Country({
    name: 'United Kingdom',
    native: 'United Kingdom',
    isoCode: 'GB',
    countryCode: '44',
  }),
  new Country({
    name: 'United States',
    native: 'United States',
    isoCode: 'US',
    countryCode: '1',
  }),
  new Country({
    name: 'Uruguay',
    native: 'Uruguay',
    isoCode: 'UY',
    countryCode: '598',
  }),
  new Country({
    name: 'Uzbekistan',
    native: 'Oʻzbekiston',
    isoCode: 'UZ',
    countryCode: '998',
  }),
  new Country({
    name: 'Vanuatu',
    native: 'Vanuatu',
    isoCode: 'VU',
    countryCode: '678',
  }),
  new Country({
    name: 'Vatican City',
    native: 'Città del Vaticano',
    isoCode: 'VA',
    countryCode: '39',
  }),
  new Country({
    name: 'Venezuela',
    native: 'Venezuela',
    isoCode: 'VE',
    countryCode: '58',
  }),
  new Country({
    name: 'Vietnam',
    native: 'Việt Nam',
    isoCode: 'VN',
    countryCode: '84',
  }),
  new Country({
    name: 'Wallis and Futuna',
    native: 'Wallis and Futuna',
    isoCode: 'WF',
    countryCode: '681',
  }),
  new Country({
    name: 'Western Sahara',
    native: '‫الصحراء الغربية‬‎',
    isoCode: 'EH',
    countryCode: '212',
  }),
  new Country({
    name: 'Yemen',
    native: '‫اليمن‬‎',
    isoCode: 'YE',
    countryCode: '967',
  }),
  new Country({
    name: 'Zambia',
    native: 'Zambia',
    isoCode: 'ZM',
    countryCode: '260',
  }),
  new Country({
    name: 'Zimbabwe',
    native: 'Zimbabwe',
    isoCode: 'ZW',
    countryCode: '263',
  }),
  new Country({
    name: 'Åland Islands',
    native: 'Åland Islands',
    isoCode: 'AX',
    countryCode: '358',
  }),
]);

COUNTRIES.getCountryByIsoCode = (isoCode) =>
  COUNTRIES.find((country) =>
    country.isoCode.toLowerCase() === isoCode.toLowerCase());

// Potentiall ambigious (see country code +1)
COUNTRIES.getCountrysByCountryCode = (countryCode) =>
  COUNTRIES.find((country) =>
    country.countryCode === countryCode);

export default COUNTRIES;
